import BaseLearnPiecesApi from './base-learn-pieces.api';

class SubmissionApi extends BaseLearnPiecesApi {
  constructor(model, apiUrl) {
    super(model, apiUrl);
  }

  async verify(evaluationId, responses, pdfName) {
    return this.post(evaluationId, '/submission/verify', {
      pdf_name: pdfName,
      learn_responses_checkboxes: responses,
      learn_responses_answers: responses,
    });
  }

  async getSubmission(evaluationId) {
    return this.get(`${evaluationId}/submission`);
  }

  async patchSubmission(evaluationId, data) {
    return this.patch(evaluationId, data);
  }
}

export default SubmissionApi;
