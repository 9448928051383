<template>
  <div class="relative w-full h-[100px]">
    <svn-pro-top-bar
      :has-primary-button="false"
      :has-secondary-button="false"
      :has-close-button="true"
      :has-left-content="false"
      :has-info-tag="!isDeleted"
      :info-tag-text="isTemplateLoading ? $t('Saving...') : $t('Saved')"
      :icon-buttons-number="2"
      :icon-buttons-array="topbarIcons"
      @on-back="goBack"
      @on-close="goToTemplateIndex"
    />

    <!-- Bandeau rouge si c'est trash -->
    <trash-warnning
      v-if="isDeleted"
      title="This template is in trash."
      @restore="restoreDeletedItems(interviewForm?.id)"
      @delete-permanently="deleteTargetRef.dialogRef.dialog  = true"
    />

    <v-layout
      full-height
      :style="isDeleted ? 'height: calc(100% - 184px);' : ' height: calc(100% - 128px);'"
      style=" position: fixed; width: 100%; z-index: 0;"
    >
      <interview-template-preview
        :attach="false"
        ref="interviewTemplatePreviewRef"
        :is-deleted="isDeleted"
      />

      <v-navigation-drawer
        v-model="drawer"
        width="320"
        disable-resize-watcher
        location="right"
        class="!h-auto"
      >
        <div class="w-full break-words">
          <div class="w-full text-start font-semibold text-gray-300 text-2xl ">
            <div class="flex justify-between items-center  pt-3 pr-3 pl-6">
              <svn-pro-title h5 large color="onSurfaceVariant">
                {{ $t( 'Chapters' ) }}
              </svn-pro-title>

              <div class="cursor-pointer">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  variant="text"
                  @click.stop="drawer = !drawer"
                  icon="custom:mingcute:close-line"
                >
                </svn-pro-icon-button>
              </div>
            </div>
          </div>

          <div
            v-for="(item, i) in headlines"
            :key="i"
            class="w-full my-2"
            :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
          >
            <div
              v-if="item.type === InterviewQuestionType.CHAPTER"
              class="uppercase font-semibold break-words px-4 pb-2"
            >
            <svn-pro-text body-large bold color="onSurface">
              {{ item.title }}
            </svn-pro-text>
            </div>

            <svn-pro-list-item
              v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
              :title="item.title "
              class="uppercase font-semibold break-words px-4 pb-2"
              :leading-icon="item.icon"
              leading-color="onSurfaceVariant"
              leading="icon"
              @click="scrollToElement(item)"
              />
          </div>
        </div>
      </v-navigation-drawer>

      <v-main class="overflow-y-auto bg-neutral-100">
        <div class="flex bg-neutral-100">
          <div class="w-full flex justify-center question-list-container">
            <template-question-list
              :is-deleted="isDeleted"
            />
          </div>
        </div>
      </v-main>
    </v-layout>
  </div>

  <svn-pro-dialog-validation
    ref="deleteTargetRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Template will be deleted`)"
    :content-text="$t('This is a permanent action.')"
    @click-primary-button="deletePermanentlyDeletedItems(interviewForm?.id)"
    @click-secondary-button="deleteTargetRef.dialogRef.dialog  = false"
  >
    <template #activator="">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import TemplateQuestionList
  from "@/components/interviewApp/template/Edit/TemplateQuestionList.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useTools from "@/tools/useTools.js";
import InterviewTemplatePreview
  from "@/components/interviewApp/template/Show/InterviewTemplatePreview.vue";
import { useMobileStore } from "@/store/mobile.js";
import { useSnackbar } from '@/store/snackbar';
import TrashWarnning from "@/components/TrashWarnning.vue"
import { InterviewQuestionType } from '@/constants/types';

const route = useRoute()
const router = useRouter()
const snackbar = useSnackbar()
const { deduceMatIconFromInterviewQuestionType } = useTools()
const { fetchInterviewForm, clearInterviewForm, restoreInterviewForm, deleteInterviewFormPermanently } = useInterviewFormStore()

const { isLargeScreen, isMobile } = storeToRefs(useMobileStore())
const { isTemplateLoading, interviewQuestions, interviewForm } = storeToRefs(useInterviewFormStore())



const drawer = ref(false);
const deleteTargetRef = ref(null);
const isDeleted = ref(false);
const interviewTemplatePreviewRef = ref(null);
const topbarIcons = ref([
  {
    icon: 'custom:mingcute:list-ordered-line',
    onClick: () => drawer.value = !drawer.value,
  },
  {
    icon: 'custom:mingcute:eye-2-line',
    onClick: () => openTemplatePreview(),
  },
])

const headlines = computed(() => {

  let separator_position = 1

  return interviewQuestions.value.map((question) => ({
    id: question.id,
    title: question.text,
    type: question.type,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    separatorpos: question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP ?
      separator_position++ : null,
  }))
})

onMounted(async() => {
  await fetchInterviewForm(route.params.id)

  isDeleted.value = interviewForm.value.status === "trashed"
})

const restoreDeletedItems = async (id) => {
  try {
    await restoreInterviewForm(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Template has been restored successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    isDeleted.value = false

  } catch (e) {
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Error while restoring a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteInterviewFormPermanently(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Template has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "interview_forms" });

  } catch (e) {
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Error while deleting permanently a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const goBack = () => {
  router.back();
}

const openTemplatePreview = async () => {
  await fetchInterviewForm(route.params.id);
  interviewTemplatePreviewRef.value.dialog = true;
}

const goToTemplateIndex = () => {
  router.push({ name: 'interview_forms' })
}

const scrollToElement = (item) => {
  router.push({ path: '', hash: `#${item.id}`})
}

onUnmounted(() => {
  clearInterviewForm()
})

fetchInterviewForm(route.params.id)

watch(isLargeScreen, (newValue) => {
  if (!newValue) {
    drawer.value = false
  }
})
</script>