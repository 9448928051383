import axiosService from '@/tools/axios-service';

class TargetsApi {
  /**
   * Fetch roadmap targets.
   * @param {Object} params - Query parameters for the request.
   * @returns {Promise<Object>} Roadmap targets and pagination metadata.
   */
  async index(params) {
    const response = await axiosService.get('/roadmaps/targets', { params });
    return response.data;
  }

  /**
   * Fetch roadmap trashed targets.
   * @param {Object} params - Query parameters for the request.
   * @returns {Promise<Object>} Trashed targets and pagination metadata.
   */
  async indexTrash(params) {
    const response = await axiosService.get('/roadmaps/targets/trashes', { params });
    return response.data;
  }

  /**
   * Delete a target by ID.
   * @param {number} id - The target ID.
   * @returns {Promise<void>}
   */
  async delete(id) {
    await axiosService.delete(`/roadmaps/targets/${id}`);
  }

  /**
   * Permanently delete a trashed target.
   * @param {number} id - The target ID.
   * @returns {Promise<void>}
   */
  async deletePermanently(id) {
    await axiosService.delete(`/roadmaps/targets/${id}/destroy_permanently`);
  }

  /**
   * Restore a trashed target by ID.
   * @param {number} id - The target ID.
   * @returns {Promise<void>}
   */
  async restore(id) {
    await axiosService.put(`/roadmaps/targets/${id}/restore`);
  }

  /**
   * Batch update targets.
   * @param {Object} payload - The payload for the batch update.
   * @returns {Promise<void>}
   */
  async batchUpdate(payload) {
    await axiosService.patch('/roadmaps/targets/batch_update', payload);
  }
}

const targetsApi = new TargetsApi();
export default targetsApi;
