<template>
  <v-dialog
    v-model="dialog"
    :attach="attach"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <template #activator>
      {{ null }}
    </template>

    <v-card class="!bg-surfaceBright">
      <svn-pro-top-bar
        :has-primary-button="false"
        :has-secondary-button="false"
        :has-close-button="true"
        :has-left-content="false"
        :has-info-tag="false"
        :icon-buttons-number="1"
        :icon-buttons-array="[{ icon: 'custom:mingcute:list-ordered-line', onClick: () => drawer = !drawer }]"
        @on-close="dialog = false"
      />

      <v-layout
        full-height
        style="position: relative; width: 100%;"
      >
        <v-navigation-drawer
          v-model="drawer"
          width="320"
          disable-resize-watcher
          location="right"
          class="!h-auto"
        >
          <div class="w-full break-words">
            <div class="w-full text-start font-semibold text-gray-300 text-2xl ">
              <div class="flex justify-between items-center  pt-3 pr-3 pl-6">
                <svn-pro-title h5 large color="onSurfaceVariant">
                  {{ $t( 'Chapters' ) }}
                </svn-pro-title>

                <div class="cursor-pointer">
                  <svn-pro-icon-button
                    color="onSurfaceVariant"
                    variant="text"
                    @click.stop="drawer = !drawer"
                    icon="custom:mingcute:close-line"
                  >
                  </svn-pro-icon-button>
                </div>
              </div>
            </div>

            <div
              v-for="(item, i) in headlines"
              :key="i"
              class="w-full my-2"
              :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
            >
              <div
                v-if="item.type === InterviewQuestionType.CHAPTER"
                class="uppercase font-semibold break-words px-4 pb-2"
              >
                <svn-pro-text body-large bold color="onSurface">
                  {{ item.title }}
                </svn-pro-text>
              </div>

                <svn-pro-list-item
                  v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
                  :title="item.title "
                  class="uppercase font-semibold break-words px-4 pb-2"
                  :leading-icon="item.icon"
                  leading-color="onSurfaceVariant"
                  leading="icon"
                  @click="scrollToElement(i)"
                />
            </div>
          </div>
        </v-navigation-drawer>

        <v-main class="overflow-y-auto flex justify-center items-start bg-surface">
          <interview-template-show-list
            :title="title"
            :description="description"
            :interview-questions="interviewQuestions"
            :video="video"
            :template-type="templateType"
          />
        </v-main>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import useTools from "@/tools/useTools.js";
import { useMobileStore } from "@/store/mobile.js";
import { InterviewQuestionType } from '@/constants/types';
import { useInterviewFormStore } from "@/store/interview-form.js";
import InterviewTemplateShowList from "@/components/interviewApp/template/Show/InterviewTemplateShowList.vue";

const props = defineProps({
  attach: { type: Boolean, default: false },
  isDeleted: { type: Boolean, required: true }
});

const dialog = ref(false);
const drawer = ref(false);

const { deduceMatIconFromInterviewQuestionType } = useTools();

const { isLargeScreen } = storeToRefs(useMobileStore());
const { title, description, interviewQuestions, video, templateType } = storeToRefs(useInterviewFormStore())

const chapters = computed(() => {
  return interviewQuestions?.value?.filter(question => question?.type === InterviewQuestionType.CHAPTER)
})

const headlines = computed(() => {
  return interviewQuestions.value.map((question) => ({
    id: question?.id,
    title: question?.text,
    type: question?.type,
    icon: deduceMatIconFromInterviewQuestionType(question?.type),
    Chapterpos: question?.type === InterviewQuestionType.CHAPTER ? chapters?.value?.indexOf(question) + 1 : null,
  }))
})

const scrollToElement = (index) => {
  let element = document.getElementById(index)
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

watch(isLargeScreen, (newValue) => {
  if (!newValue) {
    drawer.value = false
  }
});

defineExpose({
  dialog
})
</script>