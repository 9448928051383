import { defineStore } from 'pinia';
import { genericFunctions, genericStates } from '@/tools/genericFunctions.js';
import { createConsumer } from '@rails/actioncable';

export const useActionCable = defineStore({
  id: 'action-cable',
  state: () => ({
    cable: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    initActionCable() {
      const params = JSON.parse(window.localStorage.getItem('bktAccess'));

      if (params && !this.cable) {
        this.cable = createConsumer(
          `${import.meta.env.WEBSOCKET_DOMAIN}?access-token=${
            params['access-token']
          }&client=${params['client']}&uid=${params['uid']}`,
        );
      }
    },
  },
});
