<template>
  <div
    class="w-screen h-screen flex items-center justify-center px-5 md:!px-12 py-16 lg:!px-0 lg:!py-0 overflow-y-auto"
  >
    <div
      class="w-full max-w-[328px] flex h-[608px] md:h-[718px] lg:h-[536px] xl:max-w-[472px] xl:h-[592px] desktop:h-[712px] flex-col relative justify-center items-center mx-auto"
    >
      <div class="flex flex-col items-start gap-4 self-stretch">
        <div class="flex flex-col items-start gap-6 self-stretch">
          <!-- Welcome text -->
          <div class="w-full flex flex-col gap-2 items-start">
            <svn-pro-title
              :h5="!isMobile"
              :h6="isMobile"
              class="text-center"
              color="onSurface"
              medium
            >
              {{ $t('Welcome on board !') }}
            </svn-pro-title>

            <svn-pro-text body-medium class="text-center" color="onSurface" regular>
              {{ $t('Please enter your email.') }}
            </svn-pro-text>
          </div>

          <!-- Email input -->
          <svn-pro-text-field
            v-model="email"
            :error="errorEmail"
            :label="$t('Email')"
            class="w-full"
            type="text"
            variant="outlined"
            @keyup.enter="checkMail"
          />

          <!-- Hidden for autocompletion -->
          <input class="hidden" type="password" />
        </div>

        <!-- Login buttons -->
        <div class="flex flex-col items-start gap-1 self-stretch">
          <svn-pro-button :text="$t('Continue')" block variant="flat" @click="checkMail" />

          <!-- Or with the two dividers -->
          <div class="w-full flex items-center justify-center gap-2 p-2">
            <svn-pro-divider class="w-full" />

            <svn-pro-text caption class="text-center" color="onSurface">
              {{ $t('or') }}
            </svn-pro-text>

            <svn-pro-divider class="w-full" />
          </div>

          <svn-pro-button
            :text="$t('Sign in with Google')"
            block
            prepend-icon="custom:logos:google-icon"
            variant="outlined"
            @click="connect"
          />
        </div>

        <svn-pro-divider class="w-full" />

        <!-- Interested & request demo -->
        <div
          class="flex flex-col items-center md:items-start self-stretch xl:flex-row xl:items-center xl:gap-1"
        >
          <svn-pro-text body-medium class="text-center" color="onSurfaceVariant" regular>
            {{ $t('Interested in discovering more about Aleph?') }}
          </svn-pro-text>

          <span
            class="text-primary text-center text-sm font-normal leading-5 underline cursor-pointer"
            @click="router.push('/auth/request_demo')"
          >
            {{ $t('Request a demo') }}
          </span>
        </div>
      </div>

      <!-- Aleph Logo -->
      <div class="w-full flex flex-col items-start absolute top-0">
        <div
          :style="{ 'background-image': `url('${logo}')` }"
          class="flex justify-center items-center bg-cover bg-center overflow-hidden w-[120px] h-[34.5px]"
        />
      </div>
    </div>

    <div class="w-1/2 h-screen hidden xl:flex">
      <div
        :style="{ 'background-image': `url('${bgHome}')` }"
        class="flex justify-center items-center bg-cover bg-center overflow-hidden w-full h-full"
      />
    </div>
  </div>

  <v-overlay :model-value="appLoading" class="align-center justify-center">
    <svn-loader class="mx-auto" loading-size="lg" />
  </v-overlay>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import axios from '@/tools/axios-service.js';
import { useRoute, useRouter } from 'vue-router';
import logo from '@/assets/logo_aleph_white.svg';
import bgHome from '@/assets/bghome.png';
import { useUserStore } from '@/store/user.js';
import { storeToRefs } from 'pinia';
import { useSnackbar } from '@/store/snackbar.js';
import { googleSdkLoaded } from 'vue3-google-login';
import { useAppRouter } from '@/store/app-router';
import { useMobileStore } from '@/store/mobile.js';

const snackbar = useSnackbar();
snackbar.checkStatus();

const router = useRouter();
const route = useRoute();
const { email } = storeToRefs(useUserStore());
const { isMobile } = storeToRefs(useMobileStore());

const errorEmail = ref(false);
const appLoading = ref(false);
const submitLoading = ref(false);
const { bktRedirectPath } = storeToRefs(useAppRouter());

onMounted(() => {
  if (route?.query?.email_not_found) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Email not found. Please check and try again.');
    snackbar.displaySnackBar();
  } else if (route?.query?.google_payload) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Unable to process Google payload. Please try again.');
    snackbar.displaySnackBar();
  }
});

const checkMail = async () => {
  if (email.value) {
    try {
      submitLoading.value = true;
      const res = await axios.get('/users/check', { params: { email: email.value } });

      if (res.data.email_exist && res.data.password_exist) {
        router.push('sign_in');
      } else if (res.data.email_exist && !res.data.password_exist) {
        window.localStorage.setItem(
          'bktPass',
          JSON.stringify({
            email: email.value,
            timer: 0,
            mode: 'create',
          }),
        );
        router.push('email_sent_first');
      } else {
        router.push('email_not_found');
      }
    } catch (e) {
      console.log(e);
      snackbar.setBgColor('error').displaySnackBar(e.message);
    } finally {
      submitLoading.value = false;
    }
  } else {
    errorEmail.value = true;
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Please enter your email.');
    snackbar.displaySnackBar();
  }
};

const connect = () => {
  appLoading.value = true;
  googleSdkLoaded((google) => {
    google.accounts.oauth2
      .initCodeClient({
        client_id: import.meta.env.GOOGLE_CLIENT_ID,
        scope: 'email profile openid',
        redirect_uri: `${import.meta.env.APP_DOMAIN}/api/v2/external_oauth/google/callback`,
        ux_mode: 'redirect',
        state: bktRedirectPath.value,
      })
      .requestCode();
  });
  appLoading.value = false;
};

watch(email, (newValue) => {
  if (newValue && errorEmail.value) {
    errorEmail.value = false;
  }
});
</script>

<style scoped>
* html {
  overflow: hidden !important;
}

:deep(.v-field) {
  border-radius: 8px !important;
}
</style>
