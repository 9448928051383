import { acceptHMRUpdate, defineStore } from 'pinia';
import axiosService from '@/tools/axios-service.js';
import caseStyles from '@/tools/caseStyles.js';

export const useCompanyStore = defineStore('company', {
  state: () => ({
    id: null,
    applications: [],
    logo: null,
    name: null,
    links: [],
    homeBanner: null,
    homeLogo: null,
    myInterviewsBanner: null,
    myTeamInterviewsBanner: null,
    interviewBannerGif: null,
    tagCategories: null,
    tags: [],
    users: [],
    searchUsers: [],
    searchManagers: [],
    searchManagersCreators: [],
    managers: [],
    interviewForms: [],
    progression: null,
    history: [],
    modifications: [],
  }),
  actions: {
    setStates(company) {
      this.id = company.id;
      this.applications = company.applications;
      this.name = company.name;
      this.links = company.links;
      this.logo = company.logo_url;
      this.homeBanner = company.home_banner_url;
      this.homeLogo = company.home_logo_url;
      this.myInterviewsBanner = company.my_interviews_banner_url;
      this.myTeamInterviewsBanner = company.my_team_interviews_banner_url;
      this.interviewBannerGif = company.interview_banner_gif_url;
      this.tagCategories = caseStyles.convertKeysToCamel(company.tag_categories);
      this.tags = company.tags;
    },
    async fetchCompany() {
      const companyStore = useCompanyStore();
      const res = await axiosService.get('/companies/me');

      companyStore.setStates(res.data);
    },
    async fetchCompanyUsers({ disableCache = false } = {}) {
      if (disableCache || this.users.length === 0) {
        const res = await axiosService.get('/companies/get_users');

        this.users = res.data;
      }
    },
    async searchCompanyUsers(search, limit = 0) {
      try {
        // if limit is 0, users_search return all
        const res = await axiosService.get(
          `/users/users_search?text=${search}&page[per]=${limit}&only_employees=true`,
        );

        this.searchUsers = res.data.users;
      } catch (e) {
        console.log(e);
      }
    },
    async searchAllCompanyUsers({ disableCache = false } = {}) {
      const res = await axiosService.get('/companies/get_users');

      this.searchUsers = res.data;
    },
    async fetchCompanyManagers({ disableCache = false } = {}) {
      if (disableCache || this.managers.length === 0) {
        const res = await axiosService.get('/companies/get_managers');

        this.managers = res.data;
      }
    },
    async searchCompanyManagers(search, limit = 0) {
      // if limit is 0, users_search return all
      const res = await axiosService.get(
        `/users/users_search?text=${search}&page[per]=${limit}&only_managers=true`,
      );
      this.searchManagers = res.data.users;
    },
    async searchCompanyManagersCreators(search, limit = 0) {
      // if limit is 0, users_search return all
      const res = await axiosService.get(
        `/users/users_search?text=${search}&page[per]=${limit}&only_manager_creator=true`,
      );
      this.searchManagersCreators = res.data.users;
    },
    async searchAllCompanyManagers({ disableCache = false } = {}) {
      const res = await axiosService.get('/companies/get_managers');

      this.searchManagers = res.data;
    },
    async updateCompanyName(name) {
      const res = await axiosService.put(`/companies`, {
        name: name,
      });
      return res.data.name;
    },
    async updateCompanyLinks(links) {
      const res = await axiosService.put(`/companies/update_links`, {
        links: links,
      });
      this.links = res.data.links;
      return res.data.links;
    },
    async updateCompanyBannerOrLogo(type, file) {
      const headers = { 'Content-Type': 'multipart/form-data' };

      const res = await axiosService.put(
        `/companies/personnalization`,
        {
          [type]: file,
        },
        { headers },
      );
      this.homeBanner = res.data.home_banner_url;
      this.homeLogo = res.data.home_logo_url;
      this.logo = res.data.logo_url;
      this.myInterviewsBanner = res.data.my_interviews_banner_url;
      this.myTeamInterviewsBanner = res.data.my_team_interviews_banner_url;
    },
    async deleteTagCatagory(categoryId) {
      await axiosService.delete(`/companies/tag_categories/${categoryId}`);
      this.tagCategories = this.tagCategories.filter((category) => category?.id != categoryId);
    },
    async deleteTag(categoryId, tagId) {
      await axiosService.delete(`/companies/tag_categories/${categoryId}/tags/${tagId}`);
    },
    async updateTagCategoryWithTags(category, tags) {
      const update_tags = tags.map((tag) => {
        tag.name = tag.tagName;
        return tag;
      });
      const res = await axiosService.put(`/companies/tag_categories/${category.id}`, {
        name: category.name,
        tags_attributes: update_tags,
      });

      return caseStyles.convertKeysToCamel(res);
    },
    async createTagCategory(name) {
      const res = await axiosService.post(`/companies/tag_categories`, {
        name: name,
      });
      this.tagCategories.push(caseStyles.convertKeysToCamel(res.data));
    },
    async createTag(categoryId, name) {
      const res = await axiosService.post(`/companies/tag_categories/${categoryId}/tags`, {
        tag_name: name,
      });
      return res.data;
    },
    async fetchInterviewForms(type = null, { disableCache = false } = {}) {
      if (disableCache || this.interviewForms.length === 0) {
        const res = await axiosService.get(
          `/interview_app/forms/templates/${type ? '?type=' + type : ''}`,
        );

        this.interviewForms = res.data.interview_app_forms;
      }
    },
    isAppAvailable(app) {
      return this.applications.includes(app);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot));
}
