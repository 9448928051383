<template>
  <div class="w-full flex flex-col gap-5 mx-auto md:!max-w-[500px] lg:!max-w-[614px] xl:!max-w-[560px] pb-[78px]">
    <div />

    <div class="w-full flex flex-col items-center px-5 md:!px-0">
      <div class="w-full flex flex-col gap-4 md:!gap-6 xl:!gap-10 items-center">
        <!-- Header -->
        <div class="w-full flex items-center justify-between">
          <svn-pro-title h5 medium>
            {{ $t('Create a training') }}
          </svn-pro-title>
          <svn-pro-icon-button
            variant="text"
            icon="custom:mingcute:close-line"
            color="onSurfaceVariant"
            @click="BackToPreviousPage"
          />
        </div>

        <div class="w-full flex flex-col gap-6 items-center">
          <!-- Cover -->
          <div class="w-full flex flex-col gap-1 items-start">
            <svn-pro-text body-medium regular color="onSurfaceVariant">
              {{ $t('Training banner (Accepts only .jpg, .png and .jpeg file formats)') }}
            </svn-pro-text>

            <bkt-image-cover-position
              :mode="mode"
              :url="coverBlobURL ? coverBlobURL : null"
              v-model:coordinates="coordinates"
              @file-upload="uploadImage"
              @error="errorUploading"
              @change-mode="mode = $event"
            />
          </div>

          <div class="w-full flex flex-col gap-6 items-center">
            <div class="w-full flex flex-col gap-6 items-center">
              <!-- Training title -->
              <svn-pro-text-field
                :label="$t('Training title')"
                v-model="learnTraining.title"
                :error="titleError"
                :error-messages="titleError ? $t('*required') : undefined"
                :max-length="70"
                counter
                class="w-full"
              />

              <!-- Description -->
              <svn-pro-text-area
                :label="$t('Description')"
                v-model="learnTraining.description"
                :rows="6"
                :max-rows="6"
                class="w-full"
              />

              <!-- Date & Time -->
              <div class="w-full flex flex-col md:!flex-row gap-4 justify-center items-start">
                <!-- Start date -->
                <pop-up-edit-date
                  @save="updateLaunchedAtDate"
                >
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-bind="props"
                      v-model="learnTraining.launched_at_date"
                      :error="startDateError"
                      class="w-full"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      :label="$t('Start date*')"
                    />
                  </template>
                </pop-up-edit-date>

                <!-- Time -->
                <pop-up-edit-time
                  :time-value="learnTraining?.launched_at_time"
                  @save="updateLaunchedAtTime"
                >
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-bind="props"
                      v-model="learnTraining.launched_at_time"
                      class="w-full"
                      :label="`Time (${currentTimeZone})`"
                    />
                  </template>
                </pop-up-edit-time>
              </div>
            </div>

            <svn-pro-divider color="[#767680]" class="w-full border-opacity-100" />

            <div class="w-full flex flex-col gap-4 items-start">
              <svn-pro-title h6 medium>
                {{ $t('Participants') }}
              </svn-pro-title>

              <div class="w-full flex flex-col items-center p-4 border border-borderColor border-opacity-100 rounded-[8px]">
                <v-tabs
                  v-model="tab"
                  color="primary"
                >
                  <v-tab
                    value="manual"
                    :text="$t('Manual selection')"
                  />

                  <v-tab
                    value="by_category"
                    :text="$t('By category')"
                  />
                </v-tabs>

                <div class="w-full">
                  <v-window v-model="tab">
                    <v-window-item value="manual">
                      <window-selection-manual />
                    </v-window-item>

                    <v-window-item value="by_category">
                      <window-selection-by-tags
                        :participant_tags="learnTraining?.participant_filter_tag_ids"
                      />
                    </v-window-item>
                  </v-window>
                </div>
              </div>
            </div>

            <svn-pro-divider color="[#767680]" class="w-full border-opacity-100" />

            <div class="w-full flex flex-col gap-10">
              <div class="w-full flex flex-col gap-4">
                <svn-pro-title h6 medium>
                  {{ $t('Content') }}
                </svn-pro-title>

                <!-- Content list -->
                <div v-if="learnTraining?.modules_and_playlist?.length" class="w-full">
                  <transition-group>
                    <draggable
                      key="draggable"
                      class="w-full flex flex-col self-stretch gap-4"
                      item-key="item"
                      v-bind="dragOptions"
                      :delay="200"
                      :list="learnTraining?.modules_and_playlist"
                      :delay-on-touch-only="true"
                      @start="drag = true"
                      @end="drag = false"
                      @change="getListAfterDrag()"
                    >
                      <template #item="{ element }">
                        <div
                          :key="element?.id"
                          class="w-full flex justify-center items-center gap-3"
                        >
                          <div class="cursor-grab">
                            <Icon
                              icon="mingcute:dots-line"
                              height="32"
                              width="32"
                              class="text-onSurfaceVariant"
                            />
                          </div>

                          <svn-pro-card-learn
                            :card-size="isMdScreen ? 'default' : 'compact'"
                            :card-type="element?.content_type === 'Learn::Module' ? 'module_in_playlist' : 'playlist_in_training_edit'"
                            :card-image="element?.cover_url?.['500']"
                            :card-title="element?.title"
                            :is-acquired="false"
                            :count="element?.cover_urls?.length"
                            :playlist-images="element?.cover_urls?.map(url => url?.['100'])"
                            :duration="element?.duration"
                            :edit-text="element?.content_type === 'Learn::Module' ? $t('Edit module') : $t('Edit playlist')"
                            :delete-text="$t('Remove from training')"
                            :themes="element?.themes?.map(theme => theme?.name)"
                            @on-edit="editContent(element?.content_type, element?.id)"
                            @on-delete="addOrRemoveContentInTraining(element)"
                          />
                        </div>
                      </template>
                    </draggable>
                  </transition-group>
                </div>

                <!-- No content in list -->
                <div
                  v-else
                  class="w-full h-[216px] md:!h-[240px] px-6 py-4 flex flex-col justify-center items-center text-center"
                >
                  <svn-pro-text :body-medium="!isMdScreen" :body-large="isMdScreen" regular color="onSurfaceVariant">
                    {{ $t('No module or playlist in training for the moment, add some with the buttons below') }}
                  </svn-pro-text>
                </div>

                <!-- Add modules & playlist -->
                <div class="flex flex-col md:!flex-row gap-4">
                  <!-- Add modules -->
                  <modal-add-content-to-training
                    content-type="module"
                    :training-themes="learnThemes"
                    :contents="learnModules"
                    :loading="searchLoading"
                    :training-id="Number(route?.params?.id)"
                    :modules-and-playlist="learnTraining?.modules_and_playlist"
                    :selected-modules-to-add="selectedModulesToAdd"
                    @toggle-content-in-training="addOrRemoveContentInTraining($event)"
                    @toggle-search="toggleSearch"
                    @load-more-data="loadMoreDataModules"
                  >
                    <template #activator="{ props }">
                      <svn-pro-button
                        v-bind="props"
                        prepend-icon="custom:mingcute:add-line"
                        :text="$t('Add modules')"
                        class="w-full md:!w-fit"
                        variant="tonal"
                      />
                    </template>
                  </modal-add-content-to-training>

                  <!-- Add playlists -->
                  <modal-add-content-to-training
                    content-type="playlist"
                    :training-themes="learnThemes"
                    :contents="learnPlaylists"
                    :loading="searchLoading"
                    :training-id="Number(route?.params?.id)"
                    :modules-and-playlist="learnTraining?.modules_and_playlist"
                    :selected-playlists-to-add="selectedPlaylistsToAdd"
                    @toggle-content-in-training="addOrRemoveContentInTraining($event)"
                    @toggle-search="toggleSearch"
                    @load-more-data="loadMoreDataPlaylists"
                  >
                    <template #activator="{ props }">
                      <svn-pro-button
                        v-bind="props"
                        prepend-icon="custom:mingcute:add-line"
                        :text="$t('Add playlists')"
                        class="w-full md:!w-fit"
                        variant="tonal"
                      />
                    </template>
                  </modal-add-content-to-training>
                </div>
              </div>

              <!-- Launch training button -->
              <svn-pro-button
                :text="$t('Launch training')"
                class="w-full"
                variant="flat"
                :loading="loading"
                @click="launchTraining"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <svn-pro-dialog-validation
    :width="340"
    ref="sendEmailRef"
    :action-two-title="$t('No, thanks')"
    :action-one-title="$t('Yes, send emails')"
    :title="$t('Send invitation emails?')"
    :content-text="$t('Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.')"
    @click-primary-button="effectivelyLaunchTraining(true)"
    @click-secondary-button="effectivelyLaunchTraining(false)"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import draggable from "vuedraggable";
import { useSnackbar } from "@/store/snackbar";
import { onMounted, ref, watch } from "vue";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"
import ModalAddContentToTraining from "@/components/BktPopUp/Modals/learn/ModalAddContentToTraining.vue";
import WindowSelectionManual from "@/components/trainingApp/myTrainings/WindowSelectionManual.vue";
import WindowSelectionByTags from "@/components/trainingApp/myTrainings/WindowSelectionByTags.vue";
import { useLearnTrainingStore } from "@/store/learn-trainings";
import { useLearnModuleStore } from "@/store/learn-module";
import { useLearnPlaylistStore } from "@/store/learn-playlist";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { debounce } from "lodash";
import moment from "moment";
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";
import PopUpEditDate from "../../../components/popUpComponents/PopUpEditDate.vue";
import PopUpEditTime from "../../../components/popUpComponents/PopUpEditTime.vue";
import { useLearnThemesStore } from '@/store/learn-themes';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchThemes } = useLearnThemesStore();
const { resetTraining, createTraining, updateParticipantsOfTraining, updateParticipantsTagCategoryOfTraining, toggleContentInTraining } = useLearnTrainingStore();


const { fetchModules, fetchModulesOnlyWithPages } = useLearnModuleStore();
const { fetchPlaylists, fetchPlaylistsOnlyWithPages } = useLearnPlaylistStore();
const { isMdScreen } = storeToRefs(useMobileStore());
const { learnThemes } = storeToRefs(useLearnThemesStore());
const { learnModules } = storeToRefs(useLearnModuleStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());
const { learnPlaylists } = storeToRefs(useLearnPlaylistStore());

onMounted(async () => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  loadXHR(randomImage).then(async(blob) => {
    coverBlob.value = blob
    coverBlobURL.value = URL.createObjectURL(blob);
  })

  try {
    const res = await fetchModules();
    totalModulePages.value = res?.data?.meta?.pagination.total_pages
  } catch (error) {
    console.log(error)
  }

  try {
    const res = await fetchPlaylists();
    totalPlaylistPages.value = res?.data?.meta?.pagination.total_pages
  } catch (error) {
    console.log(error)
  }

  try {
    await fetchThemes()
  } catch (error) {
    console.log(error)
  }
})

const modulePage = ref(1);
const playlistPage = ref(1);
const totalModulePages = ref(null);
const totalPlaylistPages = ref(null);
const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});
const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
]);
const sendEmailRef = ref(null);
const loading = ref(false);
const contentError = ref(false);
const searchLoading = ref(false);
const titleError = ref(false);
const participantsError = ref(false);
const startDateError = ref(false);
const tab = ref(null)
const mode = ref('edit')
const drag = ref(false)
const coordinates = ref({left: 0, top: 0})
const currentTimeZone = ref(Intl.DateTimeFormat().resolvedOptions().timeZone)
const selectedModulesToAdd = ref([])
const selectedPlaylistsToAdd = ref([])
const modulesAndPlaylists = ref([])
const coverBlob = ref(null)
const coverBlobURL = ref(null)

const launchTraining = debounce(async() => {
  if (!learnTraining?.value?.title) {
    titleError.value = true
  }

  if (tab?.value == 'by_category') {
    if (!learnTraining?.value?.participant_filter_tag_ids?.length) {
      participantsError.value = true
    }
  } else {
    if (!learnTraining?.value?.participant_ids?.length) {
      participantsError.value = true
    }
  }

  if (!learnTraining?.value?.launched_at_date) {
    startDateError.value = true
  }

  if (!learnTraining?.value?.modules_and_playlist?.length) {
    contentError.value = true
  }

  if (titleError?.value || participantsError?.value || startDateError?.value) {
    snackbar.setMultiLine(true).setBgColor('onSurface').setMsg('Please fill required fields (*), add participant and content to launch training.').displaySnackBar()
  }

  if (!titleError?.value && !participantsError?.value && !startDateError?.value && !contentError?.value) {
    openSendEmailDialog();
  }
}, 300);

const openSendEmailDialog = () => {
  sendEmailRef.value.dialogRef.dialog = true
}

const effectivelyLaunchTraining = async(sendInvitation = false) => {
  loading.value = true
  const specificDateTime = moment(learnTraining.value.launched_at_date + ' ' + learnTraining?.value?.launched_at_time?? '', "YYYY-MM-DD hh:mm A");

  try {
    let training = await createTraining(
      coordinates.value?.left,
      coordinates.value?.top,
      learnTraining?.value?.title,
      learnTraining?.value?.description,
      learnTraining.value?.learn_contents,
      coverBlob.value?? learnTraining?.value?.cover_url,
      specificDateTime._d,
      currentTimeZone.value,
      sendInvitation
    )
    if (tab?.value == 'by_category') {
      await updateParticipantsTagCategoryOfTraining(training?.id, learnTraining?.value?.participant_filter_tag_ids, true)
    } else {
      await updateParticipantsOfTraining(training?.id, learnTraining?.value?.participant_ids, true)
    }

    training.learn_contents = modulesAndPlaylists?.value?.map((content) => {
      return {
        contentable_id: content?.id,
        contentable_type: content?.content_type
      }
    })

    await toggleContentInTraining(training?.id, training?.learn_contents)

    router.push({ name: "trainings" })
  } catch (error) {
    console.log(error)
  }
  loading.value = false
}

const updateLaunchedAtDate = (value) => {
  learnTraining.value.launched_at_date = moment(value).format("YYYY-MM-DD")
  // learnTraining.value.launched_at_date = value;
}

const updateLaunchedAtTime = (value) => {
  learnTraining.value.launched_at_time = value;
}

const BackToPreviousPage = () => {
  resetTraining();
  router.back();
}

const loadMoreDataModules = async({ done }) => {
  if (modulePage?.value < totalModulePages?.value) {
    try {
      const res = await fetchModulesOnlyWithPages(modulePage?.value + 1)
        if (res?.data?.learn_modules.length) {
          learnModules.value = [...learnModules?.value, ...res?.data?.learn_modules]
        }
        done('ok')
        modulePage.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const loadMoreDataPlaylists = async({ done }) => {
  if (playlistPage?.value < totalPlaylistPages?.value) {
    try {
      const res = await fetchPlaylistsOnlyWithPages(playlistPage?.value + 1)
        if (res?.data?.learn_playlists.length) {
          learnPlaylists.value = [...learnPlaylists?.value, ...res?.data?.learn_playlists]
        }
        done('ok')
        playlistPage.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const toggleSearch = async(value, type, themeIds) => {
  searchLoading.value = true
  if (type === 'module') {
    try {
      await fetchModules(value, themeIds)
    } catch (error) {
      console.log(error)
    }
  }
  else if (type === 'playlist') {
    try {
      await fetchPlaylists(value, themeIds)
    } catch (error) {
      console.log(error)
    }
  }
  searchLoading.value = false
}

const uploadImage = (blob) => {
  if (blob?.size > 12500000) {
    snackbar.setBgColor('onSurface').setMsg('Error uploading training image, file size exceeding 10Mb.').setCustomClass('mb-5').displaySnackBar()
  } else {
    coverBlob.value = blob
    coverBlobURL.value = URL.createObjectURL(blob);
  }
}

const errorUploading = (msg) => {
  snackbar.setBgColor('onSurface').setMsg(msg).setCustomClass('mb-5').displaySnackBar()
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {
        reject("Network error.")
      };
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {
            reject("Loading error:" + xhr.statusText)
            snackbar.setBgColor('onSurface').setMsg('Error uploading training image').setCustomClass('mb-5').displaySnackBar()
          }
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const addOrRemoveContentInTraining = (item) => {
  if (item?.content_type === 'Learn::Module') {
    const found = selectedModulesToAdd?.value?.find(content => content?.id === item?.id)

    if (found) {
      learnTraining.value.modules_and_playlist = learnTraining?.value?.modules_and_playlist?.filter(el => !(el?.content_type === found?.content_type && el?.id === found?.id))
      selectedModulesToAdd.value = selectedModulesToAdd?.value?.filter(el => el?.id !== found?.id)
    }
    else {
      selectedModulesToAdd.value?.push(item)
      learnTraining.value.modules_and_playlist.push(item)
    }
  }
  else if (item?.content_type === 'Learn::Playlist') {
    const found = selectedPlaylistsToAdd?.value?.find(content => content?.id === item?.id)

    if (found) {
      learnTraining.value.modules_and_playlist = learnTraining?.value?.modules_and_playlist?.filter(el => !(el?.content_type === found?.content_type && el?.id === found?.id))
      selectedPlaylistsToAdd.value = selectedPlaylistsToAdd?.value?.filter(el => el?.id !== found?.id)
    }
    else {
      selectedPlaylistsToAdd.value?.push(item)
      learnTraining.value.modules_and_playlist.push(item)
    }
  }

  modulesAndPlaylists.value = learnTraining.value.modules_and_playlist
}

const editContent = (type, contentId) => {
  if (type === 'Learn::Module') {
    router.push({ name: 'module_edit', params: { id: contentId }})
  }
  else if (type === 'Learn::Playlist') {
    router.push({ name: 'playlist_edit', params: { id: contentId }})
  }
}

const getListAfterDrag = async() => {
  modulesAndPlaylists.value = learnTraining?.value?.modules_and_playlist
}

watch(learnTraining, (newValue, oldValue) => {
  if (newValue?.title && titleError?.value) {
    titleError.value = false
  }
  if (newValue?.launched_at_date && startDateError?.value) {
    startDateError.value = false
  }
  if (newValue?.participant_filter_tag_ids?.length && participantsError?.value) {
    participantsError.value = false
  }
  if (newValue?.participant_ids?.length && participantsError?.value) {
    participantsError.value = false
  }
  if (newValue?.modules_and_playlist?.length && contentError?.value) {
    contentError.value = false
  }
}, { deep: true})
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}

.input :deep(.v-field__input) {
  padding-right: 40px;
}

.file-selector :deep(.v-input__control) {
  display: none;
}

.file-selector :deep(.mdi-pencil-outline) {
  margin-left: 17px;
  margin-top: 3px;
}

.load :deep(.v-skeleton-loader__image) {
  height: 350px;
}
</style>