<template>
  <div
    v-if="interviewForm"
    class="w-full flex flex-col gap-6"
  >
    <div
      v-if="interviewForm?.description"
      class="break-words w-full description rich-text-format"
      v-html="interviewForm?.description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="100%"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          interviewForm?.video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <template
      v-for="(question, idx) in questionsData"
      :key="idx"
    >
      <interview-answer-component
        v-if="question?.data?.type !== InterviewAppQuestionTypes?.roadmap_create && question?.data?.type !== InterviewAppQuestionTypes?.roadmap_update"
        :loop-idx="idx"
        :question="question?.data"
      />

      <roadmap-block-create
        v-else-if="question?.data?.type === InterviewAppQuestionTypes?.roadmap_create"
        :question="question?.data"
      />

      <roadmap-block-update
        v-else-if="question?.data?.type === InterviewAppQuestionTypes?.roadmap_update"
        :question="question?.data"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewAppQuestionTypes } from 'svn-ui-library/components';
import RoadmapBlockUpdate from "@/components/interviewApp/interview_answers/RoadmapBlockUpdate.vue";
import RoadmapBlockCreate from "@/components/interviewApp/interview_answers/RoadmapBlockCreate.vue";
import InterviewAnswerComponent from "@/components/interviewApp/interview_answers/InterviewAnswerComponent.vue";

const { interviewForm } = storeToRefs(useInterviewStore());

const isValidLink = computed(() => {
  return interviewForm?.value?.video && interviewForm?.value?.video?.match(/youtube\.com/)
});

const questionsData = computed(() => {
  return interviewForm?.value?.questions?.map((question) => {
    return { data: question };
  });
});
</script>

<style scoped>
#ytplayer {
  max-width: 640px;
}
</style>