import axiosService from '@/tools/axios-service';
import MetaData from '@/models/meta_data.js';

class MetadataApi {
  constructor(model, basePath) {
    this.model = model;
    this.basePath = basePath;
  }

  /**
   * Fetch filter metadata for a specific table.
   * @param {string} tableName - Name of the table for which metadata is being fetched.
   * @returns {Promise<MetaData>} A MetaData instance populated with fetched data.
   */
  async get(tableName) {
    const response = await axiosService.get(`${this.basePath}/${tableName}`);
    return this.model.fromJson(response.data);
  }
}

export const metadataApi = new MetadataApi(MetaData, '/metadata');
