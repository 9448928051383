<template>
  <div
    :id="question?.id"
    class="flex flex-col justify-center items-start gap-12"
  >
    <div class="flex flex-col justify-center items-start gap-6 self-stretch">
      <p
        v-if="!isParagraph"
        class="text-[48px] font-medium leading-normal text-fake-black"
      >
        {{ question?.title }}
      </p>

      <div
        v-if="isParagraph"
        v-html="question.title"
        class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description rich-text-format"
      />

      <div
        v-else-if="!isParagraph && question?.description"
        v-html="question.description"
        class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description rich-text-format"
      />
    </div>

    <div
      v-if="!isParagraph && !isChapter"
      class="flex flex-col justify-center items-start gap-8"
    >
      <p class="underline italic text-2xl font-normal">
        {{ $t('Updates and comments:') }}
      </p>

      <div class="flex flex-col justify-center items-start gap-[54px]">
        <div class="flex flex-col justify-center items-start gap-10">
          <!-- List of interviewees and their answers -->
          <div
            v-for="answer in question.answers"
            :key="answer.id"
            class="flex flex-col items-start gap-4"
          >
            <div class="flex items-center gap-4">
              <p class="text-[28px] text-fake-black font-semibold leading-normal pl-4">
                •{{ `  ${answer?.user?.lastname} ${answer?.user?.firstname}` }} ({{ $t('reviewer') }}) {{ $t("answer") }}:
              </p>

              <!-- Answers -->
              <div
                v-if="isRating || isMcq"
                class="px-3 py-2 bg-very-light-grey rounded"
              >
                <v-rating
                  v-if="isRating"
                  :length="objectFlip(question.options)[1]"
                  :model-value="Number(answer.value)"
                  color="darkGrey"
                  readonly
                  class="my-2"
                >
                  <template #item="iconProps">
                    <v-icon
                      :color="'darkGrey'"
                      class="mr-4"
                      @click="iconProps.onClick"
                    >
                      {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </template>
                </v-rating>

                <p v-else-if="isMcq" class="text-[32px] text-dark-grey font-semibold">
                  {{ answer.value }}
                </p>
              </div>
            </div>

            <!-- Comments -->
            <div
              v-if="(!isOpen && answer?.comments) || (isOpen && answer?.value)"
              class="flex items-center self-stretch"
            >
              <v-divider class="border-opacity-100 text-dark-grey py-3 pl-5" vertical :thickness="1"></v-divider>

              <div
                v-if="!isOpen && answer?.comments"
                v-html="answer.comments"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6 rich-text-format"
              />

              <div
                v-else-if="isOpen && answer?.value"
                v-html="answer.value"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6 rich-text-format"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import useTools from "@/tools/useTools.js";
import { InterviewQuestionType } from '@/constants/types';

const { objectFlip } = useTools();

const props = defineProps(["question", "loopIdx"]);

const isParagraph = computed(() => props.question?.type === InterviewQuestionType.PARAGRAPH);
const isChapter = computed(() => props.question?.type === InterviewQuestionType.CHAPTER);
const isRating = computed(() => props.question?.type === InterviewQuestionType.RATING);
const isOpen = computed(() => props.question?.type === InterviewQuestionType.OPEN);
const isMcq = computed(() => props.question?.type === InterviewQuestionType.MCQ);
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}
</style>