<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <svn-pro-info-tag
        :text="$t('Open question')"
        tag-type="primary"
        tag-style="filled"
        class="max-w-[900px] h-[32px] justify-self-start"
        leading-item="icon"
        icon="mingcute:question-line"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <svn-pro-text-field
          v-model="interviewQuestion.text"
          :label="$t('Question')"
          variant="outlined"
          :disabled="isDeleted"
          @update:model-value="debounceUpdateQuestion(interviewQuestion.id, {text: $event})"
        />

        <div class="h-40" :class="isMobile ? 'mb-20' : 'mb-16'">
          <quill-editor
            v-model:content="interviewQuestion.description"
            content-type="html"
            :placeholder="$t('Description')"
            :enable="!isDeleted"
            @update:content="debounceUpdateQuestion(interviewQuestion.id, {description: $event})"
          />
        </div>
      </div>

      <template-question-visible-required-for
        v-model:visible-for="visibleFor"
        v-model:required-for="requiredFor"
        :question-id="interviewQuestion?.id"
        :show-allow-comments="false"
        :allow-comments="false"
        :is-deleted="isDeleted"
        @update:visible-for-formated="debounceUpdateQuestion(interviewQuestion.id, {visible_for: $event})"
        @update:required-for-formated="debounceUpdateQuestion(interviewQuestion.id, {required_for: $event})"
      />
    </template>
  </template-header-question>
</template>

<script setup>
import { ref, watch } from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import { useInterviewFormStore } from "@/store/interview-form.js";
import TemplateQuestionVisibleRequiredFor
  from "@/components/interviewApp/template/Edit/TemplateQuestionVisibleRequiredFor.vue";
import useTools from "@/tools/useTools.js";
import { QuillEditor} from "@vueup/vue-quill";
import { debounce } from "lodash";
import { useMobileStore } from "@/store/mobile.js";
import { storeToRefs } from "pinia";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const tools = useTools();
const { updateQuestion } = useInterviewFormStore();

const visibleFor = ref(tools.deduceForWhom(props.interviewQuestion.visible_for));
const requiredFor = ref(tools.deduceForWhom(props.interviewQuestion.required_for));

const debounceUpdateQuestion = debounce(async(id, data) => {
  await updateQuestion(id, data)
}, 300);

watch(requiredFor, (newVal, oldVal) => {
  for (const newValKey in newVal) {
    if (!visibleFor.value.includes(newVal[newValKey])) {
      visibleFor.value.push(newVal[newValKey])

      debounceUpdateQuestion(props.interviewQuestion.id, {visible_for: tools.deduceToBackend(visibleFor.value)})
    }
  }
});

watch(visibleFor, (newVal, oldVal) => {
  for (const newValKey in requiredFor.value) {
    if (!newVal.includes(requiredFor.value[newValKey])) {
          const index = requiredFor.value.indexOf(requiredFor.value[newValKey])
          requiredFor.value.splice(index, 1)

          debounceUpdateQuestion(props.interviewQuestion.id, {required_for: tools.deduceToBackend(requiredFor.value)})
    }
  }
});
</script>