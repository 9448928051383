<template>
  <div :id="question.id" class="w-full flex flex-col gap-6">
    <svn-pro-form-block
      :description="question?.description"
      :has-description="question?.description?.length ? true : false"
      :has-divider="question?.description?.length ? true : false"
      :has-editable-area="false"
      :question-type="question?.type"
      :size="isMdScreen ? 'default' : 'compact'"
      :title="question?.text"
      type="textarea"
    />

    <template v-if="snapshotsUpdated?.length">
      <svn-pro-card-interview-target
        v-for="(target, idx) in snapshotsUpdated"
        :key="idx"
        :comments="target?.current_suggestion?.comment"
        :current-user-id="currentUserId"
        :has-employee-suggestion="hasEmployeeSuggestion(target)"
        :has-manager-or-crossed-suggestion="hasManagerOrCrossedSuggestion(target)"
        :has-manager-or-crossed-suggestion-and-submitted="
          hasManagerOrCrossedSuggestionAndSubmitted(target)
        "
        :interview-kind="interviewKind"
        :interview-status="status"
        :linterview-locked="locked"
        :selected-value="target?.objective_indicator?.options?.current_value"
        :size="isMdScreen ? 'default' : 'compact'"
        :target="target"
        :target-deadline="target?.due_date"
        :target-description="target?.description"
        :target-objective-indicator="target?.objective_indicator"
        :target-target-value="getTagText(target?.objective_indicator?.status)"
        :target-title="target?.title"
        :tooltip-three-content="$t('Final update')"
        :tooltip-two-content="$t('Suggested updates')"
        :variable-label-three="$t('Final update')"
        :variable-label-two="$t('Suggested updates')"
        tag-leading-item="point"
        type="update_show"
        @update-snapshot="onUpdateSnapshot($event, target?.objective_indicator?.id)"
      >
        <template v-if="hasEmployeeSuggestion(target)" #employee-suggestion>
          <svn-pro-answer-bubble-interview
            :answer="getSuggessedData('employee', target)?.proposal"
            :bubble-title="`${getSuggessedData('employee', target)?.user?.firstname} ${getSuggessedData('employee', target)?.user?.lastname}`"
            :comment="getSuggessedData('employee', target)?.comment"
            :question-type="target?.objective_indicator?.indicator_type"
            :selected-value="getSuggessedData('employee', target)?.proposal"
            :user="getSuggessedData('employee', target)?.user"
          />
        </template>

        <template v-if="hasManagerOrCrossedSuggestion(target)" #manager-cross-suggestion>
          <div class="w-full flex flex-col gap-6">
            <svn-pro-answer-bubble-interview
              v-if="getSuggessedData('employee', target)"
              :answer="getSuggessedData('employee', target)?.proposal"
              :bubble-title="`${getSuggessedData('employee', target)?.user?.firstname} ${getSuggessedData('employee', target)?.user?.lastname}`"
              :comment="getSuggessedData('employee', target)?.comment"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('employee', target)?.proposal"
              :user="getSuggessedData('employee', target)?.user"
            />

            <svn-pro-answer-bubble-interview
              v-if="
                getSuggessedData('manager', target) && locked && !isLastInterviewCompletedAndLocked
              "
              :answer="getSuggessedData('manager', target)?.proposal"
              :bubble-title="`${getSuggessedData('manager', target)?.user?.firstname} ${getSuggessedData('manager', target)?.user?.lastname}`"
              :comment="getSuggessedData('manager', target)?.comment"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('manager', target)?.proposal"
              :side="false"
              :user="getSuggessedData('manager', target)?.user"
            />

            <svn-pro-answer-bubble-interview
              v-if="getSuggessedData('manager', target) && interviewKind === 'crossed'"
              :answer="getSuggessedData('manager', target)?.proposal"
              :bubble-title="`${getSuggessedData('manager', target)?.user?.firstname} ${getSuggessedData('manager', target)?.user?.lastname}`"
              :comment="getSuggessedData('manager', target)?.comment"
              :question-type="target?.objective_indicator?.indicator_type"
              :selected-value="getSuggessedData('manager', target)?.proposal"
              :side="false"
              :user="getSuggessedData('manager', target)?.user"
            />
          </div>
        </template>

        <template
          v-if="hasManagerOrCrossedSuggestionAndSubmitted(target)"
          #manager-cross-suggestion-submitted
        >
          <svn-pro-answer-bubble-interview
            :answer="
              interviewKind === 'manager'
                ? getSuggessedData('manager', target)?.proposal
                : interviewKind === 'crossed'
                  ? getSuggessedData('crossed', target)?.proposal
                  : null
            "
            :comment="
              interviewKind === 'manager'
                ? getSuggessedData('manager', target)?.comment
                : interviewKind === 'crossed'
                  ? getSuggessedData('crossed', target)?.comment
                  : $t('Comment')
            "
            :crossed="isLastInterviewCompletedAndLocked"
            :is-last-interview-completed-and-locked="
              interviewKind === 'crossed' && isLastInterviewCompletedAndLocked
            "
            :question-type="target?.objective_indicator?.indicator_type"
            :selected-value="
              interviewKind === 'manager'
                ? getSuggessedData('manager', target)?.proposal
                : interviewKind === 'crossed'
                  ? getSuggessedData('crossed', target)?.proposal
                  : null
            "
            :user="getSuggessedData('manager', target)?.user"
            bubble-title=""
          />
        </template>
      </svn-pro-card-interview-target>
    </template>

    <div v-else class="flex flex-col justify-center items-center mx-auto my-10">
      <svn-pro-text color="onSurfaceVariant" subtitle-large>
        {{ $t('You don\u0027t have any targets for the moment.') }}
      </svn-pro-text>
    </div>
  </div>
</template>

<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from '@/store/user.js';
import { useMobileStore } from '@/store/mobile';
import axiosService from '@/tools/axios-service.js';
import { useInterviewStore } from '@/store/interview.js';
import { InterviewAppQuestionTypes } from 'svn-ui-library/components';
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';

const props = defineProps(['question']);

const unarchived = ref([]);

const snackbar = useSnackbar();
const { fetchUpdatedSnapshots } = useTargetSuggestionsStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { id: currentUserId } = storeToRefs(useUserStore());
const { snapshotsUpdated } = storeToRefs(useTargetSuggestionsStore());
const {
  employee,
  campaignId,
  id,
  status,
  locked,
  interviewKind,
  isLastInterviewCompletedAndLocked,
} = storeToRefs(useInterviewStore());

onMounted(async () => {
  try {
    await fetchTargets();
  } catch (error) {
    console.log(error);
  }
});

const getTagText = (status) => {
  switch (status) {
    case 'completed':
      return 'Completed';
    case 'in_progress':
      return 'In progress';
    case 'uncompleted':
      return 'Not validated';
    default:
      return 'Not set yet';
  }
};

const getSuggessedData = (state, target) => {
  if (state === 'employee') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'employee');
  } else if (state === 'manager') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'manager');
  } else if (state === 'crossed') {
    return target?.suggestions?.find((suggestion) => suggestion.state === 'crossed');
  }
};

const hasEmployeeSuggestion = (target) => {
  return (
    props?.question?.type === InterviewAppQuestionTypes.roadmap_update &&
    locked?.value &&
    target?.suggestions?.length > 0 &&
    interviewKind?.value === 'employee'
  );
};

const hasManagerOrCrossedSuggestion = (target) => {
  return (
    (props?.question?.type === InterviewAppQuestionTypes.roadmap_update &&
      target?.suggestions?.length &&
      target?.suggestions?.length > 0 &&
      interviewKind?.value === 'manager') ||
    interviewKind?.value === 'crossed'
  );
};

const hasManagerOrCrossedSuggestionAndSubmitted = (target) => {
  return (
    isLastInterviewCompletedAndLocked?.value &&
    (getSuggessedData('manager', target)?.proposal?.length > 0 ||
      getSuggessedData('crossed', target)?.proposal?.length > 0)
  );
};

const onUpdateSnapshot = debounce(async (event, indicatorId) => {
  try {
    await axiosService.patch(`/roadmaps/indicators/${indicatorId}`, {
      current_value: event?.current_value,
      comment: event?.comment,
      interview_id: id?.value,
    });
  } catch (error) {
    console.log(error);
    snackbar
      .setBgColor('onSurface')
      .displaySnackBar('Could not update suggestion ! Please try again.');
  }
}, 400);

const fetchTargets = async () => {
  try {
    await fetchUpdatedSnapshots(employee?.value?.id, campaignId?.value, id?.value);

    if (snapshotsUpdated?.value?.length === 0) {
      unarchived.value = null;
    } else {
      unarchived.value = snapshotsUpdated.value;
    }
  } catch (e) {
    snackbar.setBgColor('onSurface').setMsg('Error fetching targets !').displaySnackBar();
  }
};
</script>
