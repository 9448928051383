<template>
  <div
    v-if="interviewForm"
    class="w-full flex flex-col gap-6"
  >
    <div
      v-if="interviewForm?.description"
      class="break-words w-full description rich-text-format"
      v-html="interviewForm?.description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="100%"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          interviewForm?.video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <feedback-answer-component
      v-for="(question, idx) in questionsData"
      :key="idx"
      :loop-idx="idx"
      :question="question?.data"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useFeedbackStore } from "@/store/feedback";
import FeedbackAnswerComponent from "@/components/interviewApp/feedback_answers/FeedbackAnswerComponent.vue";

const { questions, interviewForm } = storeToRefs(useFeedbackStore());

const isValidLink = computed(() => {
  return interviewForm?.value?.video && interviewForm?.value?.video?.match(/youtube\.com/)
});

const questionsData = computed(() => {
  return questions?.value?.map((question) => {
    return { data: question };
  });
});
</script>