export default class MetaDataColumn {
  constructor({ answerOptions = '', name = '', operatorOptions = '', type = '',   }) {
    this.answerOptions = answerOptions;
    this.name = name;
    this.operatorOptions = operatorOptions;
    this.type = type;
  }

  static fromJson(data) {
    return new MetaDataColumn({
      answerOptions: data?.answer_options || '',
      name: data?.name || '',
      operatorOptions: data?.operator_options || '',
      type: data?.type || '',
    });
  }
}
