<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center pt-5 px-5 md:!px-12"
    >
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Targets templates') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <svn-pro-button
              :text="$t('New template')"
              class="hidden md:flex"
              prepend-icon="custom:mingcute:add-line"
              variant="flat"
              @click="goToNewTemplateCreation"
            />

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-fill"
                  v-bind="props"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <modal-view-deleted-items
                  ref="deletedItemsRef"
                  :headers="trashesHeaders"
                  :items="roadmapTrashesTemplates"
                  :pagination="paginationTrashes"
                  content-type="Template"
                  @restore="restoreDeletedItems"
                  @view-item="goToTemplateShow"
                  @close-modal="closeModal"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyDeletedItems"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      :active="false"
                      density="compact"
                      v-bind="props"
                      value="view_deleted"
                    >
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted templates') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Search -->
        <svn-pro-text-field
          v-model="currentSearch" :placeholder="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line" variant="outlined"
          @update:model-value="search"
        />
      </div>

      <!-- Table -->
      <template-table
        :loading="loading"
        :pagination="paginationTemplates"
        :search="currentSearch"
        :templates="roadmapTemplates"
        @fetch-new-page="updateParamsAndFetch"
        @delete-template="deleteTemplate"
      />
    </div>

    <svn-pro-floating-action-button
      v-if="!isMdScreen"
      :text="$t('New template')"
      class="fixed bottom-4 right-4"
      extended
      prepend-icon="custom:mingcute:add-line"
      variant="primary"
      @click="goToNewTemplateCreation"
    />
  </div>
</template>

<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import i18n from '@/plugins/i18n.js';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar.js';
import axiosService from '@/tools/axios-service.js';
import TemplateTable from '@/components/roadmapApp/template/Table.vue';
import { useRoadmapTemplateStore } from '@/store/roadmap-template.js';
import ModalViewDeletedItems from '../../../components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';

onMounted(async () => {
  loading.value = true;
  try {
    await fetchRoadmapTemplates();
    fetchRoadmapTrashesTemplates();
  } catch (error) {
    snackbar.setBgColor('error').displaySnackBar('Error fetching templates !');
  }
  loading.value = false;
});

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const { roadmapTemplates, roadmapTrashesTemplates, params } =
  storeToRefs(useRoadmapTemplateStore());

const router = useRouter();
const snackbar = useSnackbar();
const {
  restoreRoadmapTemplate,
  deletePermanentlyRoadmapTemplate,
  updateParams,
  setRoadmapTemplates,
  deleteRoadmapTemplate,
} = useRoadmapTemplateStore();

const paginationTemplates = ref({});
const paginationTrashes = ref({});
const loading = ref(false);
const deletedItemsRef = ref(null);
const currentSearch = ref('');
const templateToDelete = ref(null);
const trashesHeaders = ref([
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Targets template'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
  {
    align: 'start',
    key: 'delete',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);

const fetchRoadmapTemplates = async (page = 1, title = '') => {
  roadmapTemplates.value = null;
  const req = await axiosService.get(`/roadmaps/templates`, {
    params: {
      'page[number]': page,
      title,
    },
  });
  roadmapTemplates.value = req?.data?.objective_elements;
  paginationTemplates.value = req?.data?.meta?.pagination;
};

const fetchRoadmapTrashesTemplates = async (page = 1, title = '') => {
  roadmapTrashesTemplates.value = null;
  const req = await axiosService.get(`/roadmaps/templates/trashes`, {
    params: {
      'page[number]': page,
      title,
    },
  });
  roadmapTrashesTemplates.value = req?.data?.objective_elements;
  paginationTrashes.value = req?.data?.meta?.pagination;
};

const search = debounce(() => {
  reloadAfterSearch(1, currentSearch?.value);
}, 400);

const update = async (page = 1, title) => {
  try {
    await fetchRoadmapTemplates(page, title);
  } catch (error) {
    snackbar.setBgColor('error').displaySnackBar('Error fetching templates !');
  }
};

const deleteTemplate = debounce(async (id) => {
  try {
    await deleteRoadmapTemplate(id);
    const tmpTemplates = roadmapTemplates.value.filter((obj) => obj.id !== id);
    setRoadmapTemplates(tmpTemplates);
    snackbar
      .setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
      .setBgColor('onSurface')
      .displaySnackBar('Template has been deleted successfully.');
  } catch (error) {
    snackbar
      .setCustomClass(isMobile?.value ? 'mb-[88px]' : '')
      .setBgColor('error')
      .displaySnackBar('Error deleting your template !');
  }
}, 300);

const updateParamsAndFetch = (page) => {
  loading.value = true;
  update(page, currentSearch?.value);
  loading.value = false;
};

const reloadAfterSearch = async (page = 1, title) => {
  loading.value = true;
  update(page, title);
  loading.value = false;
};

const goToTemplateShow = (id) => {
  router.push({ name: 'roadmaps_templates_show', params: { id } });
};

const goToNewTemplateCreation = () => {
  router.push({ name: 'roadmaps_templates_new' });
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchRoadmapTrashesTemplates(1, value);
  } catch (e) {
    console.error(e);
  }
}, 300);

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false;
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true;

  templateToDelete.value = id;
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTemplate(id);
    roadmapTrashesTemplates.value = roadmapTrashesTemplates.value.filter(
      (trash) => trash.id !== id,
    );
    goToTemplateShow(id);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Template restored successfully');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while restoring a template');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async () => {
  try {
    await deletePermanentlyRoadmapTemplate(templateToDelete?.value);
    roadmapTrashesTemplates.value = roadmapTrashesTemplates.value.filter(
      (trash) => trash.id !== templateToDelete?.value,
    );
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Template has been deleted successfully.');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while deleting permanently a training');
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '');
    snackbar.displaySnackBar();
  }
  templateToDelete.value = null;
};
</script>

<style scoped></style>
