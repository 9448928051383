
<template>
  <div
    v-if="isFeedBack360() || isSurvey()"
    class="flex"
    :class="!isLgScreen ? 'justify-start' : 'justify-end'"
  >
    <div class="flex flex-col gap-3" :class="!isLgScreen ? 'w-full' : ''">
      <div v-if="showAllowComments" class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-allow-comment`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Allow comments') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="allowComments"
          color="primary"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-allow-comment`"
          @update:model-value="format('update:allow-comment', $event)"
          />
      </div>

      <div class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <svn-pro-text body-large regular class="text-start w-[260px]">
          {{ $t('Required') }}
        </svn-pro-text>

        <svn-pro-switch
          :model-value="requiredFor"
          color="primary"
          value="Manager"
          hide-details
          :disabled="isDeleted"
          @update:model-value="format('update:required-for', $event)"
          />
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex"
    :class="!isLgScreen ? 'justify-start' : 'justify-end'"
  >
    <div class="flex flex-col gap-3" :class="!isLgScreen ? 'w-full' : ''">
      <div v-if="showAllowComments" class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-allow-comment`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Allow comments') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="allowComments"
          color="primary"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-allow-comment`"
          @update:model-value="format('update:allow-comment', $event)"
          />
      </div>

      <div class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-visible-for-manager`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Visible for interviewer') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="visibleFor"
          color="primary"
          value="Manager"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-visible-for-manager`"
          @update:model-value="format('update:visible-for', $event)"
        />
      </div>

      <div class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-required-for-manager`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Required for interviewer') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="requiredFor"
          color="primary"
          value="Manager"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-required-for-manager`"
          @update:model-value="format('update:required-for', $event)"
        />
      </div>

      <div class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-visible-for-employee`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Visible for interviewee') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="visibleFor"
          color="primary"
          value="Employee"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-visible-for-employee`"
          @update:model-value="format('update:visible-for', $event)"
          />
      </div>

      <div class="flex items-center px-4" :class="!isLgScreen ? 'justify-between' : 'justify-center'">
        <label :for="`${questionId}-required-for-employee`">
          <svn-pro-text body-large regular class="text-start w-[260px]">
            {{ $t('Required for interviewee') }}
          </svn-pro-text>
        </label>

        <svn-pro-switch
          :model-value="requiredFor"
          color="primary"
          value="Employee"
          hide-details
          :disabled="isDeleted"
          :switch-id="`${questionId}-required-for-employee`"
          @update:model-value="format('update:required-for', $event)"
          />
      </div>
    </div>
  </div>
</template>

<script setup>

import { storeToRefs } from "pinia";
import useTools from "@/tools/useTools.js";
import { useMobileStore } from "@/store/mobile.js";
import { useInterviewFormStore } from "@/store/interview-form.js";

const props = defineProps({
  isDeleted: { type: Boolean, required: true },
  visibleFor: {type: Array, required: true},
  requiredFor: {type: Array, required: true},
  allowComments: { type: Boolean, required: true },
  showAllowComments: { type: Boolean, required: true },
  questionId: { type: Number, required: true },
});

const {isLgScreen} = storeToRefs(useMobileStore())

const emit = defineEmits([
  'update:visible-for',
  'update:visible-for-formated',
  'update:required-for',
  'update:required-for-formated',
  'update:allow-comment',
])

const tools = useTools()

const { isFeedBack360, isSurvey } = useInterviewFormStore()

const format = (eventName, event) => {
  emit(eventName, event)
  let formated
  if (isFeedBack360() || isSurvey()) {
    if(event.includes('Manager')) {
        formated = 'all'
    } else {
        formated = 'none'
    }
  } else {
    formated = tools.deduceToBackend(event)
  }

  emit(`${eventName}-formated`, formated)
}
</script>