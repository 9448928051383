<template>
  <svn-pro-form-block
    :id="String(question?.id)"
    :question-type="question?.type"
    :has-title="question?.text?.length ? true : false"
    :title="`${question?.text} ${
      question?.required_for === interviewKind ||
        question?.required_for === 'all' ? '*' : ''
    }`"
    :rating-length="question?.options"
    :selected-value="null"
    :disabled="true"
    :MCQOptions="MCQOptions"
    :type="getFormBlockType(question?.type)"
    :comments="null"
    :size="isMdScreen ? ComponentSizes?.default : ComponentSizes?.compact"
    :has-divider="question?.type === InterviewAppQuestionTypes?.chapter"
    :has-description="question?.description?.length ? true : false"
    :description="question?.description"
    :has-variable-content="false"
    :has-comment="false"
    :has-comment-area="question?.allow_comments"
    :has-editable-area="question?.type !== InterviewAppQuestionTypes?.chapter &&
      question?.type !== InterviewAppQuestionTypes?.paragraph &&
        question?.type !== InterviewAppQuestionTypes?.roadmap_update &&
          question?.type !== InterviewAppQuestionTypes?.roadmap_create
    "
  />
</template>

<script setup>
import { computed }  from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useInterviewStore } from "@/store/interview.js";
import { FormBlockTypes, ComponentSizes, InterviewAppQuestionTypes } from 'svn-ui-library/components'

const { isMdScreen } = storeToRefs(useMobileStore());
const { interviewKind } = storeToRefs(useInterviewStore());

const props = defineProps(["question", "loopIdx"]);

const MCQOptions = computed(() => {
  if (props?.question?.options?.length) {
    return props?.question?.options?.map(option => option?.value)
  } else {
    return []
  }
});

const getFormBlockType = (questionType) => {
  if ([InterviewAppQuestionTypes?.chapter, InterviewAppQuestionTypes?.paragraph, InterviewAppQuestionTypes?.open]?.includes(questionType)) {
    return FormBlockTypes?.text_area
  } else if (questionType === InterviewAppQuestionTypes?.rating) {
    return FormBlockTypes?.icon_buttons
  } else if (questionType === InterviewAppQuestionTypes?.mcq) {
    return FormBlockTypes?.radio_buttons
  } else {
    return FormBlockTypes?.text_area
  }
}
</script>