<template>
  <div class="w-full h-full relative bg-surface">
    <div class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center pt-5 px-5 md:!px-12">
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4 xl:!px-0">
        <div class="w-full flex gap-4 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Targets') }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <!-- New target button -->
            <modal-create-new-target ref="refModalCreateNewTarget" @view-item="goToTargetShow">
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  variant="flat"
                  prepend-icon="custom:mingcute:add-line"
                  :text="$t('New target')"
                  class="hidden md:flex"
                />
              </template>
            </modal-create-new-target>

            <!-- Archive, delete, view recently deleted -->
            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  icon="custom:mingcute:more-2-fill"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item
                  @click="batchUpdate('archive')"
                  value="archive_selected"
                  :disabled="isButtonDisabled"
                >
                  {{ $t('Archive selected targets', { selected: selectedUserCount }) }}
                </v-list-item>

                <svn-pro-dialog-validation
                  :width="312"
                  :action-two-title="$t('Cancel')"
                  :action-one-title="$t('Delete')"
                  :title="$t('Target(s) will be deleted')"
                  :content-text="$t('Deleted targets are stored for 30 days. After this period, they will be permanently deleted.')"
                  @click-primary-button="batchUpdate('delete')"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      value="delete_selected"
                      class="text-error"
                      :disabled="isButtonDisabled"
                      >
                      {{ $t('Delete selected targets', { selected: selectedUserCount }) }}
                    </v-list-item>
                  </template>
                </svn-pro-dialog-validation>

                <modal-view-deleted-items
                  ref="deletedItemsRef"
                  :headers="trashesHeaders"
                  :items="roadmapTrashesTargets"
                  :pagination="paginationTrashes"
                  content-type="Target"
                  @view-item="goToTargetShow"
                  @restore="restoreTarget"
                  @close-modal="closeModal"
                  @toggle-search="toggleSearchDeletedItems"
                  @delete-permanently="deletePermanentlyTarget"
                >
                  <template #activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :active="false"
                      value="view_deleted"
                    >
                      <svn-pro-text body-large regular>
                        {{ $t('View deleted targets') }}
                      </svn-pro-text>
                    </v-list-item>
                  </template>
                </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>
      </div>

      <advanced-table
        :loading="loading"
        :target-search="targetSearch"
        :target-page="targetPage"
        :target-filter="targetFilter"
        :pagination="pagination"
        :roadmap-targets="roadmapTargets"
        :variant="variant"
        :selectedUserCount="selectedUserCount"
        :selectedUserIds="selectedUserIds"
        @refetch-data="refetchTarget"
        @delete-target="deleteTarget"
        @updateVariant="updateVariant"
      />
    </div>

    <svn-pro-floating-action-button
      v-if="!isMdScreen"
      extended
      class="fixed bottom-4 right-4"
      variant="primary"
      :text="$t('New target')"
      prepend-icon="custom:mingcute:add-fill"
      @click="openModalNewTarget"
    />
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, onMounted, computed } from "vue";
import i18n from "@/plugins/i18n.js";
import { useRouter } from "vue-router";
import targetsApi from "@/apis/roadmap/target.api.js";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import AdvancedTable from "@/components/roadmapApp/target/AdvancedTable.vue";
import ModalCreateNewTarget from "@/components/BktPopUp/Modals/ModalCreateNewTarget.vue";
import ModalViewDeletedItems from "@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue";

import {
  DataTableVariant
} from 'svn-ui-library/components';

onMounted(async() => {
  if (!authorizedAccessLevel?.value?.includes(accessLevel.value)) {
    router.push({ name: "my_roadmaps" });
  }
  loading.value = true;
  try {
    await fetchTargets();
    fetchTrashedTargets();
  } catch (error) {
    snackbar.setBgColor('error').setMsg('Error fetching targets').setCustomClass(isMobile?.value ? 'mb-[88px]' : '').displaySnackBar();
  }
  loading.value = false;
})

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const { accessLevel } = storeToRefs(useUserStore());

const router = useRouter();
const snackbar = useSnackbar();

const loading = ref(false);
const targetSearch = ref('');
const targetPage = ref(1)
const targetFilter = ref([])
const selectedUserIds = ref([])
const roadmapTargets = ref([])
const roadmapTrashesTargets = ref([])
const pagination = ref({})
const params = ref({})

const targetToDelete = ref(null);
const deletedItemsRef = ref(null);
const variant = ref(DataTableVariant.MANUAL_SELECTION);
const paginationTrashes = ref({});
const refModalCreateNewTarget = ref(null);
const authorizedAccessLevel = ref(["super_admin", "admin", "manager_creator"]);

const isButtonDisabled = computed(() => {
  return DataTableVariant.MANUAL_SELECTION === variant.value && !selectedUserIds.value.length;
});

const trashesHeaders = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "deleted_at",
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "restore",
    sortable: false,
    title:"",
    width: 40,
    minWidth: 40,
  },
  {
    align: "start",
    key: "delete",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
])

const selectedUserCount = computed(() => {
  const totalCount = pagination.value?.total_count ?? 0; // Default to 0 if total_count is null/undefined
  const selectedCount = selectedUserIds.value.length;

  switch (variant.value) {
    case DataTableVariant.MANUAL_SELECTION:
      // Count selected users
      return selectedCount;

    case DataTableVariant.MANUAL_DESELECTION:
      // Count unselected users
      return totalCount - selectedCount;

    default:
      // Return total count
      return totalCount;
  }
});

const fetchTargets = async () => {
  try {
    const response = await targetsApi.index(params.value);
    roadmapTargets.value =  response.objective_elements
    pagination.value = response.meta.pagination
  } catch (error) {
    console.error('Error fetching targets:', error);
  }
};

const fetchTrashedTargets = async (page = 1, title = '') => {
  try {
    const data = await targetsApi.indexTrash({
      'page[number]': page,
      title: title,
    });

    roadmapTrashesTargets.value = data?.objective_elements;
    paginationTrashes.value = data?.meta?.pagination;
  } catch (error) {
    console.error('Error fetching trashed targets:', error);
  }
};

const deleteTarget = async (id) => {
  try {
    await targetsApi.delete(id);
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("onSurface")
      .setMsg('Target deleted successfully.')
      .displaySnackBar();
    fetchTargets();
    fetchTrashedTargets();
  } catch (error) {
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("error")
      .setMsg('Error while deleting the target.')
      .displaySnackBar();
  }
};

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashedTargets(1, value)
  } catch (e) {
    console.error(e);
  }
}, 300)

const restoreTarget = async (id) => {
  try {
    await targetsApi.restore(id);
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("onSurface")
      .setMsg('Target restored successfully.')
      .displaySnackBar();
    await fetchTrashedTargets();
    await fetchTargets();
  } catch (error) {
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("error")
      .setMsg('Error while restoring the target.')
      .displaySnackBar();
  }
};

const deletePermanentlyTarget = async () => {
  try {
    await targetsApi.deletePermanently(targetToDelete?.value);
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("onSurface")
      .setMsg('Target ahs been deleted successfully.')
      .displaySnackBar();
    fetchTargets();
    fetchTrashedTargets();
  } catch (error) {
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("error")
      .setMsg('Error while deleting permanently a training.')
      .displaySnackBar();
  }
  targetToDelete.value = null
};

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true

  targetToDelete.value = id
}

const goToTargetShow = (targetId) => {
  router.push({ name: 'roadmaps_targets_show', params: { id: targetId } })
}

const openModalNewTarget = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true
}

const refetchTarget = debounce(async(newPage, title, filteredData) => {
  try {
    targetPage.value = newPage
    targetSearch.value = title
    targetFilter.value = filteredData

    params.value = {
      'page[number]': newPage,
      title: title,
      filter_data: JSON.stringify(targetFilter.value),
    }

    await fetchTargets()
  } catch (error) {
    console.log(error)
  }
}, 400)

const updateVariant = (newVariant, ids) => {
  variant.value = newVariant;
  selectedUserIds.value = ids;
};

const batchUpdate = async (actionType) => {
  try {
    await targetsApi.batchUpdate({
      title: targetSearch.value,
      filter_data: JSON.stringify(targetFilter.value),
      variant: variant.value,
      selected_target_ids: selectedUserIds.value,
      action_type: actionType,
    });

    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor("onSurface")
      .setMsg(actionType === 'delete'
        ? 'Targets have been deleted successfully.'
        : 'Targets have been archived successfully.')
      .displaySnackBar();

      selectedUserIds.value = [];
    fetchTargets();
    fetchTrashedTargets();
  } catch (error) {
    console.log(error)
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : '')
      .setBgColor('error')
      .setMsg(actionType === 'delete'
        ? 'Error while deleting targets.'
        : 'Error while archiving targets.')
      .displaySnackBar();
  }
};
</script>
