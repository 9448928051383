<template>
  <div v-if="loading" class="w-full h-screen flex flex-col justify-center items-center">
    <svn-pro-progress-circular
      :size="48"
      :width="5"
      indeterminate
    />
  </div>

  <div v-else class="w-full flex flex-col mx-auto">
    <div v-if="amICreatorOfEntityOrAdmin(id)" class="w-full flex sticky top-[64px] z-50">
      <svn-pro-top-bar
        :has-primary-button="false"
        :has-secondary-button="false"
        :has-close-button="false"
        :has-left-content="false"
        :has-info-tag="false"
        :icon-buttons-number="2"
        :icon-buttons-array="topbarIcons"
      />
  
      <div class="w-fit self-end -mt-6">
        <svn-pro-menu
          v-model="optionsMenu"
          :items="menuItems"
        >
          <template #activator>
            {{ null }}
          </template>
        </svn-pro-menu>
      </div>
    </div>

    <div class="w-full flex flex-col gap-6 pt-6">
      <div class="w-full flex flex-col gap-2 md:!gap-6 md:!justify-between px-5 md:!px-0 md:!max-w-[672px] lg:!max-w-[614px] xl:!max-w-[560px] mx-auto items-start">
        <!-- Title -->
        <svn-pro-title
          medium
          :h5="!isMdScreen"
          :h4="isMdScreen"
        >
          {{ learnTraining?.title }}
        </svn-pro-title>
      </div>

      <!-- Tabs -->
      <v-tabs
        v-if="amICreatorOfEntityOrAdmin(id)"
        v-model="tab"
        :grow="!isMdScreen"
        color="primary"
        align-tabs="center"
      >
        <v-tab
          value="overview"
          :text="$t('Overview')"
        />

        <v-tab
          value="analytics"
          :text="$t('Analytics')"
        />
      </v-tabs>

      <!-- Windows -->
      <v-window v-model="tab">
        <v-window-item value="overview">
          <overview
            :training="learnTraining"
            :is-deleted="isDeleted"
          />
        </v-window-item>

        <v-window-item v-if="amICreatorOfEntityOrAdmin(id)" value="analytics">
          <analytics
            :loading="loading"
            :status="learnTraining?.status"
            :training-id="route?.params?.id?.toString()"
            :training-completion-all="trainingCompletionAll"
            :completion-by-module="completionByModule"
            :is-deleted="isDeleted"
            :training-participants-list="trainingParticipantsList"
            @get-training-participants="getTrainingParticipantsList(route?.params?.id, $event)"
          />
        </v-window-item>
      </v-window>
    </div>
  </div>

  <!-- Send email dialog -->
  <svn-pro-dialog-validation
    :width="312"
    ref="sendReminderEmailDialog"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Send')"
    :title="$t('Send reminder?')"
    :content-text="$t('Every people who has not finished the training will receive a reminder.')"
    @click-primary-button="sendEmailReminders"
    @click-secondary-button="sendReminderEmailDialog.dialogRef.dialog = false"
  >
    <template #activator>
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>

  <!-- Delete training dialog -->
  <svn-pro-dialog-validation
    ref="deleteTrainingDialog"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Training will be deleted`)"
    :content-text="$t('Deleted trainings are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteLearnTraining"
    @click-secondary-button="deleteTrainingDialog.dialogRef.dialog = false"
  >
    <template #activator>
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-floating-action-button
    id="fab"
    to-top
    size="small"
    color="primary"
    variant="tonal"
    :rounded="'lg'"
    class="fixed bottom-4 right-4 bg-white"
    icon="custom:mingcute:arrow-to-up-line"
  />
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import Overview from './Overview.vue';
import Analytics from './Analytics.vue';
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from '@/store/mobile';
import { useRoute, useRouter } from 'vue-router';
import { useLearnTrainingStore } from '@/store/learn-trainings';
import { useAnalyticTrainingsStore } from "@/store/analytic-trainings.js";

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { amICreatorOfEntityOrAdmin } = useUserStore();
const { fetchTraining, resetTraining, deleteTraining, sendReminder } = useLearnTrainingStore();
const { getTrainingParticipantsList, getCompletionByModule, getTrainingCompletionAll } = useAnalyticTrainingsStore();

const { id } = storeToRefs(useUserStore());
const { isMdScreen } = storeToRefs(useMobileStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());
const { completionByModule, trainingCompletionAll, trainingParticipantsList } = storeToRefs(useAnalyticTrainingsStore());

const loading = ref(false);
const tab = ref('overview');
const isDeleted = ref(false);
const deleteTrainingDialog = ref(null);
const sendReminderEmailDialog = ref(null);
const optionsMenu = ref(false);
const topbarIcons = ref([
  {
    icon: 'custom:mingcute:edit-2-line',
    onClick: () => editTraining(),
  },
  {
    icon: 'custom:mingcute:more-2-line',
    onClick: () => toggleMenuState(),
  },
])
const menuItems = ref([
  {
    title: 'Send reminder email',
    value: 'send_reminder',
    onClick: () => openSendReminderEmailDialog(),
  },
  {
    error: true,
    title: 'Delete training',
    value: 'delete_training',
    onClick: () => openDeleteTrainingDialog(),
  },
])

onMounted(async() => {
  resetScrollPosition();

  resetTraining();
  loading.value = true

  try {
    await fetchTraining(route?.params?.id)
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error fetching training').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').displaySnackBar();
  }

  loading.value = false

  await getCompletionByModule(route?.params?.id);
  await getTrainingCompletionAll(route?.params?.id);
  await getTrainingParticipantsList(route?.params?.id, '');

  isDeleted.value = learnTraining.value.status === "trash"
});

// Reset Scroll Position, because coming from a module the scroll is not at the top
const resetScrollPosition = () => {
  if (window.history.state) {
    const currentState = { ...window.history.state }
    delete currentState.scroll
    window.history.replaceState(currentState, '')
  }

  router.replace({ path: router.currentRoute.value.fullPath, force: true })
}

const editTraining = () => {
  router.push({ name: "training_edit", params: { id: learnTraining?.value?.id } })
}

const toggleMenuState = () => {
  optionsMenu.value = !optionsMenu.value;
}

const openSendReminderEmailDialog = () => {
  sendReminderEmailDialog.value.dialogRef.dialog = true
}

const openDeleteTrainingDialog = () => {
  deleteTrainingDialog.value.dialogRef.dialog = true
}

const sendEmailReminders = async () => {
  try {
    await sendReminder(learnTraining?.value?.id)
    sendReminderEmailDialog.value.dialogReminderEmailTraining = false

    snackbar.setBgColor('onSurface').setMsg('Reminder email has been sent successfully.').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('onSurface').setMsg('Error sending reminder email.').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').displaySnackBar();
  }
}

const deleteLearnTraining = async() => {
  try {
    await deleteTraining(learnTraining?.value?.id);
    deleteTrainingDialog.value.dialogRef.dialog = false;

    snackbar.setBgColor('onSurface').setMsg('Training has been deleted successfully.').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').displaySnackBar();

    router.push({ name: "trainings" });
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error deleting training.').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').displaySnackBar();
  }
}
</script>