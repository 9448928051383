import MetaDataColumn from '@/models/meta_data_column.js';

export default class MetaData {
  constructor({ columns = []}) {
    this.columns = columns;
  }

  static fromJson(data) {
    return new MetaData({
      columns: data?.columns?.map(column => MetaDataColumn.fromJson(column)) || [],
    });
  }
}
