<template>
  <div class="w-full flex flex-col px-5 md:!px-12 lg:!px-0 gap-5 pb-[70px]">
    <div />

    <div class="w-full relative flex flex-col gap-4 md:!gap-4 lg:!gap-6 desktop:!gap-10 items-center justify-center max-w-[1075px] xl:!max-w-[1250px] mx-auto">
      <!-- Header -->
      <div class="w-full lg:!max-w-[614px] xl:!max-w-[752px] flex items-center justify-between mx-auto">
        <svn-pro-title h5 medium>
          {{ $t('Create a campaign') }}
        </svn-pro-title>

        <svn-pro-button
          variant="text"
          color="onSurfaceVariant"
          icon="custom:mingcute:close-line"
          @click="deleteAndRedirect(campaignDraft?.id)"
        />
      </div>

      <div class="w-full relative flex flex-row items-start justify-center">
        <svn-pro-timeline
          v-if="isLgScreen"
          :items="steps"
          :currentStepIndex="indexStep"
          :lastStepIndex="lastStep"
          @change-timeline="changeTimeline"
          class="!h-[376px] absolute left-0"
        />

        <div class="w-full lg:!max-w-[614px] xl:!max-w-[752px] flex flex-col p-6 border-borderColor border-opacity-100 border rounded-[12px]">
          <component
            :is="steps[indexStep]?.component"
            @previous="previous"
            @next="next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import Launch from "@/components/interviewApp/campaignDraft/Launch.vue";
import Settings from "@/components/interviewApp/campaignDraft/Settings.vue";
import Templates from "@/components/interviewApp/campaignDraft/Templates.vue";
import CampaignDraftTitle from "@/components/interviewApp/campaignDraft/Title.vue";
import Participants from "@/components/interviewApp/campaignDraft/Participants.vue";

export default {
  components: {
    CampaignDraftTitle,
    Settings,
    Participants,
    Templates,
    Launch,
  },
  setup() {
    const { t } = useI18n();
    const { fetchCampaignDraft } = useCampaignDraftStore();

    const { campaignDraft } = storeToRefs(useCampaignDraftStore());
    const { isLgScreen } = storeToRefs(useMobileStore());

    const indexStep = ref(0);
    const lastStep = ref(0);

    const steps = [
      { title: t("Set campaign"), component: "CampaignDraftTitle", step: "title" },
      { title: t("Settings"), component: "settings", step: "settings" },
      { title: t("Participant(s)"), component: "participants", step: "participants" },
      { title: t("Template(s)"), component: "templates", step: "templates" },
      { title: t("Launch!"), component: "launch", step: "launch" },
      ];

    fetchCampaignDraft().then(() => {
      const idx = steps.findIndex(
        (item) => item.component === campaignDraft.value?.step
      );

      if (idx > -1) {
        indexStep.value = idx;
        lastStep.value = idx;
      }
    });

    return {
      isLgScreen,
      indexStep,
      steps,
      lastStep,
      campaignDraft
    };
  },
  methods: {
    changeTimeline(index) {
      const snackbar = useSnackbar();
      const { campaignDraft } = storeToRefs(useCampaignDraftStore());
      const lastFilledStep = this.steps.findIndex((item) => item.component === campaignDraft.value.step);

      if (index <= lastFilledStep) {
        this.indexStep = index;
      } else {
        snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("You need to finish this step first.").displaySnackBar()
      }
    },
    async next() {
      const snackbar = useSnackbar();
      const { updateCampaignDraft } = useCampaignDraftStore();

      const { campaignDraft } = storeToRefs(useCampaignDraftStore());
      const newIndex = this.indexStep + 1;

      if (this.steps[newIndex]) {
        this.indexStep = newIndex;

        this.lastStep = this.lastStep < newIndex ? newIndex : this.lastStep;
        try {
          await updateCampaignDraft({ campaign_draft: { step: this.steps[newIndex].step } });
          campaignDraft.value.step = this.steps[newIndex].step;
        } catch (error) {
          snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again.").displaySnackBar()
        }
      } else {
        // TODO: launch campaign
      }
    },
    async previous() {
      const snackbar = useSnackbar();
      const { updateCampaignDraft } = useCampaignDraftStore();

      const { campaignDraft } = storeToRefs(useCampaignDraftStore());
      const newIndex = this.indexStep - 1;

      if (this.steps[newIndex]) {
        this.indexStep = newIndex;

        this.lastStep = this.lastStep < newIndex ? newIndex : this.lastStep;
        try {
          await updateCampaignDraft({ campaign_draft: { step: this.steps[newIndex].step } });
          campaignDraft.value.step = this.steps[newIndex].step;
        } catch (error) {
          snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again.").displaySnackBar()
        }
      }
    },
    async deleteAndRedirect(id) {
      const { deleteCampaignDraft } = useCampaignDraftStore();

      deleteCampaignDraft(id);
      this.$router.push({ name: "campaigns" });
    },
  },
};
</script>
