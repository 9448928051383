<template>
  <svn-pro-advanced-table
    v-model="selectedIds"
    :activeFilterCount="targetFilter.length"
    :variant="variant"
    :headers="roadmapTargets?.length ? headers : []"
    :items="roadmapTargets ?? []"
    :items-per-page="10"
    :loading="roadmapTargets ? loading : true"
    :show-select="!!roadmapTargets?.length"
    :pagination-total-pages="pagination?.total_pages"
    :total-count="pagination?.total_count"
    :page="pagination?.current_page"
    :filterItems="filterList"
    :showSelect="true"
    :selectedOnResultCount="selectedUserCount"
    :showFilter="true"
    @selected-items="updateVariant"
    @toggle-filter-visibility="toggleFilterVisibility"
    @refetch="refetchData"
  >

    <template #filter-items>
      <svn-pro-filter
        v-show="shouldShow"
        :filterItems="filterList"
        class="flex"
        @update-selected-data-filters="updateSelectedDataFilters"
        @set-data-after-search="refetchAndSetFilterItemData"
        @load-more-data="loadMoreData"
      />
    </template>

    <template #item="{ item, handleRowSelection, isChecked }">
      <tr class="hover:bg-[#46464F14]/[0.08] active:bg-[#46464F1F]/[0.12] cursor-pointer" @click="goToTargetShow(item?.id)">
        <td class="px-2">
          <v-checkbox
            :model-value="isChecked(item.id)"
            hide-details
            @update:model-value="handleRowSelection(item.id)"
            @click.stop
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            medium
            body-large
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="avatar_text"
            :avatar="item?.employee?.avatar?.['50']"
            :text="`${item?.employee?.firstname} ${item?.employee?.lastname}`"
          />
        </td>

        <td>
          <my-roadmaps-objective-indicator :objective-indicator="item?.objective_indicator" />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.due_date, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <svn-pro-dialog-validation
                icon="noto:warning"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Delete')"
                :title="$t('Target will be deleted')"
                :content-text="$t('Deleted targets are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteTarget(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :active="false" value="delete_template">
                    <svn-pro-text body-large regular color="error">
                      {{ $t('Delete target') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="targetSearch ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('New target')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="targetSearch ? $t('Oops, we didn’t find any results matching your search.') :
          $t('Hmm, it seems there is no target yet. Create your first target')"
        @click-primary="openNewTargetModal"
      />
    </template>
  </svn-pro-advanced-table>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import MyRoadmapsObjectiveIndicator from "../MyRoadmapsObjectiveIndicator.vue";
import { debounce } from "lodash";
import axiosService from "@/tools/axios-service.js";
import { metadataApi } from "@/apis/metadata.api.js";
import { useRouter } from "vue-router";

const props = defineProps({
  variant: { type: String, default: '' },
  targetSearch: { type: String, default: '' },
  targetPage: { type: Number, default: 1 },
  selectedUserCount: { type: Number, default: 1 },
  targetFilter: { type: Array, default: () => [] },
  selectedUserIds: { type: Array, default: () => [] },
  loading: { type: Boolean, required: true },
  roadmapTargets: { type: Object, default: () => {} },
  pagination: {type: Object, default: () => { return {} }},
})

const emit = defineEmits(['fetch-new-page-page', 'delete-target', 'refetch-data', 'update-variant']);

const { isMdScreen } = storeToRefs(useMobileStore());
const router = useRouter();

const filterInstance = ref(null); // To hold the filter model instance
const selectedIds = ref([])
const userCurrentPage = ref(null)
const userTotalPages = ref(null)

onMounted(async() => {
  await getFilterColumns()
})

const refModalCreateNewTarget = ref(null)
const shouldShow = ref(false)
const activeFilterCount = ref(null);
const filterList = ref([]);

const filterHeaders = ref([
  {
    name: "title",
    title: i18n.global.t("Targets"),
  },
  {
    name: "objectivable_id",
    key: "objectivable_id",
    title: i18n.global.t("Users"),
    icon: "",
  },
  {
    name: "completion",
    title: i18n.global.t("Completion"),
  },
  {
    name: "indicator",
    title: i18n.global.t("Indicator"),
  },
  {
    name: "due_date",
    title: i18n.global.t("Deadline"),
  },
]);

const headers = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 300,
  },
  {
    align: "start",
    key: "fullname",
    sortable: false,
    title: i18n.global.t('People'),
    minWidth: 250,
  },
  {
    align: "start",
    key: "completion",
    sortable: false,
    title: i18n.global.t('Completion'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "options",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
]);

const indicatorTypeValues = ref([
  {
    text: i18n.global.t('True/False'),
    value: 'boolean'
  },
  {
    text: i18n.global.t('Numeric Value'),
    value: 'numeric_value'
  },
  {
    text: i18n.global.t('Percentage'),
    value: 'percentage'
  },
  {
    text: i18n.global.t('Multi choice'),
    value: 'multi_choice'
  },
]);

const completionValues = ref([
  {
    text: i18n.global.t('Not started'),
    value: 'uncompleted'
  },
  {
    text: i18n.global.t('In Progress'),
    value: 'in_progress'
  },
  {
    text: i18n.global.t('Completed'),
    value: 'completed'
  },
]);

const updateVariant = (newVariant, ids) => {
  emit('update-variant', newVariant, ids)
};

const deleteTarget = async (id) => {
  emit('delete-target', id)
}

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value; // Toggle `shouldShow` state
};

const openNewTargetModal = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true
}

const refetchData = (newPage, title) => {
  emit("refetch-data", newPage, title, props.targetFilter);
};

const updateSelectedDataFilters = debounce(async (data) => {
  activeFilterCount.value = data?.length;

  // Convert `text` back to `value` for indicators
  const convertedData = data.map((filter) => {
    if (filter.name === "indicator" && Array.isArray(filter.value)) {
      return {
        ...filter,
        value: filter.value.map((item) => {
          const found = indicatorTypeValues.value.find((type) => type.text === item);
          return found ? found.value : item; // Fallback to original item if not found
        }),
      };
    } else if (filter.name === "completion" && Array.isArray(filter.value)) {
      return {
        ...filter,
        value: filter.value.map((item) => {
          const found = completionValues.value.find((type) => type.text === item);
          return found ? found.value : item; // Fallback to original item if not found
        }),
      };
    }
    return filter; // Keep other filters unchanged
  });

  emit("refetch-data", 1, props.targetSearch, convertedData.length ? convertedData : {});
}, 300);

// Main method to handle fetching and processing filter columns
const getFilterColumns = async () => {
  if (!filterInstance.value || !filterInstance.value.columns.length) {
    try {
      // Fetch metadata and initialize the Filter model
      filterInstance.value = await metadataApi.get("objective_elements");

      // Get the header names from filterHeaders
      const headerNames = filterHeaders.value.map(header => header.name);

      // Filter and sort the columns based on headerNames, then process them concurrently
      const matchedColumns = filterInstance.value.columns
        .filter(column => headerNames.includes(column.name)) // Filter matched columns
        .sort((a, b) => headerNames.indexOf(a.name) - headerNames.indexOf(b.name)); // Sort based on filterHeaders

      // Process each column
      for (let matchedColumn of matchedColumns) {
        await processColumn(matchedColumn)
      }
    } catch (error) {
      console.error("Error fetching filter metadata:", error);
    }
  }
};

// Process a single column
const processColumn = async (column) => {
  const foundCol = filterHeaders.value?.find(el => el?.name === column?.name);

  if (!foundCol) return; // Skip if no matching filter header is found

  if (column?.name === "objectivable_id") {
    await handleObjectivableIdColumn(column, foundCol);
  } else {
    await handleGenericColumn(column, foundCol);
  }
};

// Handle all other generic columns
const handleGenericColumn = async (column, foundCol) => {
  const items = retrieveColumnItems(column);
  const newCol = createFilterColumn(column, foundCol, items);
  filterList?.value?.push(newCol);
};

// Function to retrieve items for a specific column based on its name
const retrieveColumnItems = (column) => {
  const { name, answerOptions } = column || {};
  const items = answerOptions?.items;

  switch (name) {
    case 'indicator':
      return mapIndicatorItems(items);
    case 'completion':
      return mapCompletionItems(items);
    default:
      return items;
  }
};

// Map "indicator" items to their localized text values
const mapIndicatorItems = (items) => {
  return items.map(item => {
    const found = indicatorTypeValues.value.find(val => val.value === item);
    return found ? found.text : item; // Fallback to the original item
  });
};

// Map "completion" items to their localized text values
const mapCompletionItems = (items) => {
  return items.map(item => {
    const found = completionValues.value.find(val => val.value === item);
    return found ? found.text : item; // Fallback to the original item
  });
};


// Create a new filter column object
const createFilterColumn = (column, foundCol, items) => {
  return {
    value: '',
    operatorOptions: column?.operatorOptions,
    items,
    name: column?.name,
    type: column?.type,
    title: foundCol?.title,
  };
};

const goToTargetShow = (targetId) => {
  router.push({ name: 'roadmaps_targets_show', query: { from: 'targets' }, params: { id: targetId } })
}

// Reusable API fetcher for user data
const fetchUserData = async (params = {}) => {
  try {
    const { data } = await axiosService.get(`/users`, { params });

    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Let the caller handle specific actions
  }
};

// Set pagination data
const updatePaginationData = (paginationMeta) => {
  userCurrentPage.value = paginationMeta?.current_page || 0;
  userTotalPages.value = paginationMeta?.total_pages || 0;
};

// Refetch and set filter item data
const refetchAndSetFilterItemData = async (name, text) => {
  if (name !== 'objectivable_id') return;

  try {
    const data = await fetchUserData({
      'page[per]': 25,
      title: text,
    });

    updatePaginationData(data?.meta.pagination);

    const targetFilter = filterList.value.find((filter) => filter.name === 'objectivable_id');
    if (targetFilter) {
      targetFilter.items = data?.users || [];
    }
  } catch (error) {
    console.error('Error in refetchAndSetFilterItemData:', error);
  }
};

// Handle the specific case for "objectivable_id" column
const handleObjectivableIdColumn = async (column, foundCol) => {
  try {
    const data = await fetchUserData({ 'page[per]': 25 });

    updatePaginationData(data?.meta.pagination);

    const newCol = createFilterColumn(column, foundCol, data?.users || []);
    filterList.value.push(newCol);
  } catch (error) {
    console.error('Error handling objectivable_id column:', error);
  }
};

// Load more data for a specific filter
const loadMoreData = async (name) => {
  if (name !== 'objectivable_id') return;
  if (userCurrentPage.value >= userTotalPages.value) return

  try {
    const data = await fetchUserData({
      'page[per]': 25,
      'page[number]': userCurrentPage.value + 1,
    });

    const targetFilter = filterList.value.find((filter) => filter.name === 'objectivable_id');
    if (targetFilter) {
      targetFilter.items = [...(targetFilter.items || []), ...(data?.users || [])];
    }

    userCurrentPage.value += 1;
  } catch (error) {
    console.error('Error loading more data:', error);
  }
};

watch(props, (newValue, oldValue) => {
  selectedIds.value = newValue?.selectedUserIds
})
</script>
