import apiService from '@/tools/axios-service';

class BaseInterviewFormApi {
  async fetch(type, page) {
    const res = await apiService.get(
      `/interview_app/forms/templates/${type ? '?type=' + type : ''}&page[number]=${page}`,
    );
    return res?.data;
  }
}

export const interviewFormApi = new BaseInterviewFormApi();
