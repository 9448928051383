<template>
  <trash-warnning
    v-if="learnTraining?.status === 'trash'"
    title="This training is in trash."
    @restore="restoreDeletedItem"
    @delete-permanently="openDeletePermanentlyDialog"
  />

  <div v-if="pageLoading" class="w-full h-screen flex flex-col justify-center items-center">
    <svn-pro-progress-circular :size="48" :width="5" indeterminate />
  </div>

  <div
    v-else
    class="w-full flex flex-col gap-5 mx-auto md:!max-w-[500px] lg:!max-w-[614px] xl:!max-w-[560px] pb-[78px]"
  >
    <div />

    <div class="w-full flex flex-col items-center px-5 md:!px-0">
      <div class="w-full flex flex-col gap-4 md:!gap-6 xl:!gap-10 items-center">
        <!-- Header -->
        <div class="w-full flex items-center justify-between">
          <svn-pro-title h5 medium>
            {{ learnTraining?.title }}
          </svn-pro-title>

          <svn-pro-icon-button
            :disabled="learnTraining?.status === 'trash'"
            color="onSurfaceVariant"
            icon="custom:mingcute:close-line"
            variant="text"
            @click="goBack"
          />
        </div>

        <div class="w-full flex flex-col gap-6 items-center">
          <!-- Cover -->
          <div class="w-full flex flex-col gap-1 items-start">
            <svn-pro-text body-medium color="onSurfaceVariant" regular>
              {{ $t('Training banner (Accepts only .jpg, .png and .jpeg file formats)') }}
            </svn-pro-text>

            <bkt-image-cover-position
              :coordinates="{
                left: coordinates?.left ?? learnTraining?.cover_offset_left,
                top: coordinates?.top ?? learnTraining?.cover_offset_top,
              }"
              :disabled="learnTraining?.status === 'trash'"
              :mode="mode"
              :url="coverBlobURL ?? learnTraining?.cover_url?.['original']"
              @error="errorUploading"
              @update:coordinates="coordinates = { left: $event.left, top: $event.top }"
              @file-upload="uploadImage"
              @change-mode="mode = $event"
            />
          </div>

          <div class="w-full flex flex-col gap-6 items-center">
            <div class="w-full flex flex-col gap-6 items-center">
              <!-- Training title -->
              <svn-pro-text-field
                v-model="learnTraining.title"
                :disabled="learnTraining?.status === 'trash'"
                :error="titleError"
                :error-messages="titleError ? $t('*required') : undefined"
                :label="$t('Training title*')"
                :max-length="70"
                class="w-full"
                counter
              />

              <!-- Description -->
              <svn-pro-text-area
                v-model="learnTraining.description"
                :disabled="learnTraining?.status === 'trash'"
                :label="$t('Description')"
                :max-rows="6"
                :rows="6"
                class="w-full"
              />

              <!-- Date & Time -->
              <div class="w-full flex flex-col md:!flex-row gap-4 justify-center items-start">
                <!-- Start date -->
                <pop-up-edit-date
                  :date-value="learnTraining?.launched_at_date"
                  @save="updateLaunchedAtDate"
                >
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-model="launchedAtDate"
                      :disabled="learnTraining?.status === 'trash'"
                      :error="startDateError"
                      :label="$t('Start date*')"
                      class="w-full"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      v-bind="props"
                    />
                  </template>
                </pop-up-edit-date>

                <!-- Time -->
                <pop-up-edit-time
                  :time-value="learnTraining?.launched_at_time"
                  @save="updateLaunchedAtTime"
                >
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-model="learnTraining.launched_at_time"
                      :disabled="learnTraining?.status === 'trash'"
                      :label="`Time (${currentTimeZone})`"
                      class="w-full"
                      v-bind="props"
                    />
                  </template>
                </pop-up-edit-time>
              </div>
            </div>

            <svn-pro-divider class="w-full border-opacity-100" color="[#767680]" />

            <div class="w-full flex flex-col gap-4 items-start">
              <svn-pro-title h6 medium>
                {{ $t('Participants') }}
              </svn-pro-title>

              <div
                class="w-full flex flex-col items-center p-4 border border-borderColor border-opacity-100 rounded-[8px]"
              >
                <v-tabs v-model="tab" :disabled="learnTraining?.status === 'trash'" color="primary">
                  <v-tab :text="$t('Manual selection')" value="manual" />

                  <v-tab :text="$t('By category')" value="by_category" />
                </v-tabs>

                <!-- Windows -->
                <div class="w-full">
                  <v-window v-model="tab">
                    <v-window-item value="manual">
                      <window-selection-manual :disabled="learnTraining?.status === 'trash'" />
                    </v-window-item>

                    <v-window-item value="by_category">
                      <window-selection-by-tags
                        :disabled="learnTraining?.status === 'trash'"
                        :participant_tags="learnTraining?.participant_filter_tag_ids"
                      />
                    </v-window-item>
                  </v-window>
                </div>
              </div>
            </div>

            <svn-pro-divider class="w-full border-opacity-100" color="[#767680]" />

            <div class="w-full flex flex-col gap-10">
              <div class="w-full flex flex-col gap-4">
                <svn-pro-title h6 medium>
                  {{ $t('Content') }}
                </svn-pro-title>

                <!-- Content list -->
                <div v-if="learnTraining?.modules_and_playlist?.length" class="w-full">
                  <v-card :disabled="learnTraining?.status === 'trash'" variant="text">
                    <transition-group>
                      <draggable
                        key="draggable"
                        :delay="200"
                        :delay-on-touch-only="true"
                        :list="learnTraining?.modules_and_playlist"
                        class="w-full flex flex-col self-stretch gap-4"
                        item-key="item"
                        v-bind="dragOptions"
                        @change="getListAfterDrag()"
                        @end="drag = false"
                        @start="drag = true"
                      >
                        <template #item="{ element }">
                          <div
                            :key="element?.id"
                            class="w-full flex justify-center items-center gap-3"
                          >
                            <div class="cursor-grab">
                              <Icon
                                class="text-onSurfaceVariant"
                                height="32"
                                icon="mingcute:dots-line"
                                width="32"
                              />
                            </div>

                            <svn-pro-card-learn
                              :card-image="element?.cover_url?.['500']"
                              :card-size="isMdScreen ? 'default' : 'compact'"
                              :card-title="element?.title"
                              :card-type="
                                element?.content_type === 'Learn::Module'
                                  ? 'module_in_playlist'
                                  : 'playlist_in_training_edit'
                              "
                              :count="element?.cover_urls?.length"
                              :delete-text="$t('Remove from training')"
                              :duration="element?.duration"
                              :edit-text="
                                element?.content_type === 'Learn::Module'
                                  ? $t('Edit module')
                                  : $t('Edit playlist')
                              "
                              :is-acquired="false"
                              :playlist-images="element?.cover_urls?.map((url) => url?.['100'])"
                              :themes="element?.themes?.map((theme) => theme?.name)"
                              @on-edit="editContent(element?.content_type, element?.id)"
                              @on-delete="addOrRemoveContentInTraining(element)"
                            />
                          </div>
                        </template>
                      </draggable>
                    </transition-group>
                  </v-card>
                </div>

                <!-- No content in list -->
                <div
                  v-else
                  class="w-full h-[216px] md:!h-[240px] px-6 py-4 flex flex-col justify-center items-center text-center"
                >
                  <svn-pro-text
                    :body-large="isMdScreen"
                    :body-medium="!isMdScreen"
                    color="onSurfaceVariant"
                    regular
                  >
                    {{
                      $t(
                        'No module or playlist in training for the moment, add some with the buttons below',
                      )
                    }}
                  </svn-pro-text>
                </div>

                <!-- Add modules & playlist -->
                <div class="flex flex-col md:!flex-row gap-4">
                  <!-- Add modules -->
                  <modal-add-content-to-training
                    :contents="learnModules"
                    :loading="searchLoading"
                    :modules-and-playlist="learnTraining?.modules_and_playlist"
                    :selected-modules-to-add="selectedModulesToAdd"
                    :training-id="Number(route?.params?.id)"
                    :training-themes="learnThemes"
                    content-type="module"
                    @toggle-content-in-training="addOrRemoveContentInTraining($event)"
                    @toggle-search="toggleSearch"
                    @load-more-data="loadMoreDataModules"
                  >
                    <template #activator="{ props }">
                      <svn-pro-button
                        :disabled="learnTraining?.status === 'trash'"
                        :text="$t('Add modules')"
                        class="w-full md:!w-fit"
                        prepend-icon="custom:mingcute:add-line"
                        v-bind="props"
                        variant="tonal"
                      />
                    </template>
                  </modal-add-content-to-training>

                  <!-- Add playlists -->
                  <modal-add-content-to-training
                    :contents="learnPlaylists"
                    :loading="searchLoading"
                    :modules-and-playlist="learnTraining?.modules_and_playlist"
                    :selected-playlists-to-add="selectedPlaylistsToAdd"
                    :training-id="Number(route?.params?.id)"
                    :training-themes="learnThemes"
                    content-type="playlist"
                    @toggle-content-in-training="addOrRemoveContentInTraining($event)"
                    @toggle-search="toggleSearch"
                    @load-more-data="loadMoreDataPlaylists"
                  >
                    <template #activator="{ props }">
                      <svn-pro-button
                        :disabled="learnTraining?.status === 'trash'"
                        :text="$t('Add playlists')"
                        class="w-full md:!w-fit"
                        prepend-icon="custom:mingcute:add-line"
                        v-bind="props"
                        variant="tonal"
                      />
                    </template>
                  </modal-add-content-to-training>
                </div>
              </div>

              <!-- Update training button -->
              <svn-pro-button
                :disabled="learnTraining?.status === 'trash'"
                :loading="loading"
                :text="$t('Update training')"
                class="w-full"
                variant="flat"
                @click="onUpdateTraining"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Send email dialog -->
  <svn-pro-dialog-validation
    ref="sendEmailRef"
    :action-one-title="$t('Yes, send emails')"
    :action-two-title="$t('No, thanks')"
    :content-text="
      $t(
        'Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched.',
      )
    "
    :title="$t('Send invitation emails?')"
    :width="340"
    @click-primary-button="effectivelyUpdateTraining(true)"
    @click-secondary-button="effectivelyUpdateTraining(false)"
  >
    <template #activator="{ props }">
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>

  <!-- Confirmation dialog -->
  <svn-pro-dialog-validation
    ref="confirmationRef"
    :action-one-title="$t('Yes, leave')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('Are you sure you want to leave ? Your changes will be lost.')"
    :title="$t('Your changes will be lost')"
    :width="350"
    @click-primary-button="BackToPreviousPage"
    @click-secondary-button="confirmationRef.dialogRef.dialog = false"
  >
    <template #activator="{ props }">
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="deleteTrainingRef"
    :action-one-title="$t('Delete')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('This is a permanent action.')"
    :title="$t(`Training will be deleted`)"
    icon="noto:warning"
    @click-primary-button="deletePermanentlyDeletedItem"
    @click-secondary-button="deleteTrainingRef.dialogRef.dialog = false"
  >
    <template #activator="{ props }">
      <div class="hidden" />
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import draggable from 'vuedraggable';
import { useSnackbar } from '@/store/snackbar';
import { computed, onMounted, ref, watch } from 'vue';
import ModalAddContentToTraining from '@/components/BktPopUp/Modals/learn/ModalAddContentToTraining.vue';
import WindowSelectionManual from '@/components/trainingApp/myTrainings/WindowSelectionManual.vue';
import WindowSelectionByTags from '@/components/trainingApp/myTrainings/WindowSelectionByTags.vue';
import { useLearnTrainingStore } from '@/store/learn-trainings';
import { useLearnModuleStore } from '@/store/learn-module';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import BktImageCoverPosition from '@/components/image/bkt-image-cover-position.vue';
import PopUpEditDate from '../../../components/popUpComponents/PopUpEditDate.vue';
import PopUpEditTime from '../../../components/popUpComponents/PopUpEditTime.vue';
import TrashWarnning from '@/components/TrashWarnning.vue';
import { useLearnThemesStore } from '@/store/learn-themes';

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchThemes } = useLearnThemesStore();
const { fetchModules, fetchModulesOnlyWithPages } = useLearnModuleStore();
const { fetchPlaylists, fetchPlaylistsOnlyWithPages } = useLearnPlaylistStore();
const {
  resetTraining,
  fetchTraining,
  updateTraining,
  restoreTraining,
  deleteTrainingPermanently,
  updateParticipantsOfTraining,
  updateParticipantsTagCategoryOfTraining,
  toggleContentInTraining,
} = useLearnTrainingStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { learnThemes } = storeToRefs(useLearnThemesStore());
const { learnModules } = storeToRefs(useLearnModuleStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());
const { learnPlaylists } = storeToRefs(useLearnPlaylistStore());

onMounted(async () => {
  resetTraining();
  pageLoading.value = true;

  try {
    await fetchTraining(route?.params?.id);
    launchedAtDate.value = moment(learnTraining?.value?.launched_at_date).format('YYYY-MM-DD');
    if (learnTraining?.value?.participant_filter_tag_ids?.length) {
      tab.value = 'by_category';
    }
    modulesAndPlaylists.value = learnTraining?.value?.modules_and_playlist;
    initialData.value = { ...learnTraining?.value };
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error fetching training')
      .setCustomClass('mb-5')
      .displaySnackBar();
  }

  pageLoading.value = false;

  try {
    const res = await fetchModules();
    totalModulePages.value = res?.data?.meta?.pagination.total_pages;
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error fetching modules')
      .setCustomClass('mb-5')
      .displaySnackBar();
  }

  try {
    const res = await fetchPlaylists();
    totalPlaylistPages.value = res?.data?.meta?.pagination.total_pages;
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error fetching playlists')
      .setCustomClass('mb-5')
      .displaySnackBar();
  }

  try {
    await fetchThemes();
  } catch (error) {
    console.log(error);
  }

  selectedModulesToAdd.value = learnTraining.value.modules_and_playlist.filter((content) => {
    return content.content_type === 'Learn::Module';
  });

  selectedPlaylistsToAdd.value = learnTraining.value.modules_and_playlist.filter((content) => {
    return content.content_type === 'Learn::Playlist';
  });
});

const modulePage = ref(1);
const playlistPage = ref(1);
const totalModulePages = ref(null);
const totalPlaylistPages = ref(null);
const tab = ref(null);
const drag = ref(false);
const mode = ref('edit');
const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});
const loading = ref(false);
const initialData = ref({});
const coverBlob = ref(null);
const coordinates = ref(null);
const titleError = ref(false);
const pageLoading = ref(false);
const coverBlobURL = ref(null);
const sendEmailRef = ref(null);
const contentError = ref(false);
const searchLoading = ref(false);
const launchedAtDate = ref(null);
const confirmationRef = ref(null);
const startDateError = ref(false);
const deleteTrainingRef = ref(null);
const modulesAndPlaylists = ref([]);
const participantsError = ref(false);
const selectedModulesToAdd = ref([]);
const selectedPlaylistsToAdd = ref([]);
const currentTimeZone = ref(Intl.DateTimeFormat().resolvedOptions().timeZone);
const trainingIsNotEdited = computed(() => {
  return isEqual(learnTraining?.value, initialData?.value);
});

const onUpdateTraining = debounce(async () => {
  if (!learnTraining?.value?.title) {
    titleError.value = true;
  }

  if (tab?.value == 'by_category') {
    if (!learnTraining?.value?.participant_filter_tag_ids?.length) {
      participantsError.value = true;
    }
  } else {
    if (!learnTraining?.value?.participant_ids?.length) {
      participantsError.value = true;
    }
  }

  if (!learnTraining?.value?.launched_at_date) {
    startDateError.value = true;
  }

  if (!learnTraining?.value?.modules_and_playlist?.length) {
    contentError.value = true;
  }

  if (titleError?.value || participantsError?.value || startDateError?.value) {
    snackbar
      .setMultiLine(true)
      .setBgColor('onSurface')
      .setMsg('Please fill required fields (*), add participant and content to launch training.')
      .displaySnackBar();
  }

  if (
    !titleError?.value &&
    !participantsError?.value &&
    !startDateError?.value &&
    !contentError?.value
  ) {
    openSendEmailDialog();
  }
}, 300);

const openSendEmailDialog = () => {
  sendEmailRef.value.dialogRef.dialog = true;
};

const openDeletePermanentlyDialog = () => {
  deleteTrainingRef.value.dialogRef.dialog = true;
};

const effectivelyUpdateTraining = async (sendInvitation = false) => {
  loading.value = true;
  const specificDateTime = moment(
    launchedAtDate?.value + ' ' + learnTraining?.value?.launched_at_time ?? '',
    'YYYY-MM-DD hh:mm A',
  );

  try {
    if (tab?.value == 'by_category') {
      await updateParticipantsTagCategoryOfTraining(
        learnTraining?.value?.id,
        learnTraining?.value?.participant_filter_tag_ids,
        true,
      );
    } else {
      await updateParticipantsOfTraining(
        learnTraining?.value?.id,
        learnTraining?.value?.participant_ids,
        true,
      );
    }

    await updateTraining(
      learnTraining?.value?.id,
      coordinates?.value?.left ?? learnTraining.value?.cover_offset_left,
      coordinates?.value?.top ?? learnTraining.value?.cover_offset_top,
      learnTraining?.value?.title,
      learnTraining?.value?.description,
      learnTraining?.value?.learn_contents,
      coverBlob.value ?? learnTraining?.value?.cover_url,
      specificDateTime._d,
      currentTimeZone.value,
      sendInvitation,
    );

    learnTraining.value.learn_contents = modulesAndPlaylists.value.map((content) => {
      return {
        contentable_id: content?.id,
        contentable_type: content?.content_type,
      };
    });

    await toggleContentInTraining(learnTraining?.value?.id, learnTraining?.value?.learn_contents, sendInvitation);

    snackbar
      .setBgColor('onSurface')
      .setMsg('Training updated successfully')
      .setCustomClass('mb-5')
      .displaySnackBar();

    router.push({ name: 'trainings' });
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
};

const updateLaunchedAtDate = (value) => {
  launchedAtDate.value = moment(value).format('YYYY-MM-DD');
  learnTraining.value.launched_at_date = value;
};

const updateLaunchedAtTime = (value) => {
  learnTraining.value.launched_at_time = value;
};

const goBack = () => {
  if (trainingIsNotEdited?.value) {
    BackToPreviousPage();
  } else {
    confirmationRef.value.dialogRef.dialog = true;
  }
};

const BackToPreviousPage = () => {
  router.back();
};

const loadMoreDataModules = async ({ done }) => {
  if (modulePage?.value < totalModulePages?.value) {
    try {
      const res = await fetchModulesOnlyWithPages(modulePage?.value + 1);
      if (res?.data?.learn_modules.length) {
        learnModules.value = [...learnModules?.value, ...res?.data?.learn_modules];
      }
      done('ok');
      modulePage.value++;
    } catch (error) {
      done('error');
    }
  } else {
    done('empty');
  }
};

const loadMoreDataPlaylists = async ({ done }) => {
  if (playlistPage?.value < totalPlaylistPages?.value) {
    try {
      const res = await fetchPlaylistsOnlyWithPages(playlistPage?.value + 1);
      if (res?.data?.learn_playlists.length) {
        learnPlaylists.value = [...learnPlaylists?.value, ...res?.data?.learn_playlists];
      }
      done('ok');
      playlistPage.value++;
    } catch (error) {
      done('error');
    }
  } else {
    done('empty');
  }
};

const toggleSearch = async (value, type, themeIds) => {
  searchLoading.value = true;
  if (type === 'module') {
    try {
      await fetchModules(value, themeIds);
    } catch (error) {
      console.log(error);
    }
  } else if (type === 'playlist') {
    try {
      await fetchPlaylists(value, themeIds);
    } catch (error) {
      console.log(error);
    }
  }
  searchLoading.value = false;
};

const uploadImage = async (blob) => {
  if (blob?.size > 12500000) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error uploading training image, file size exceeding 10Mb.')
      .setCustomClass('mb-5')
      .displaySnackBar();
  } else {
    coverBlob.value = blob;
    coverBlobURL.value = URL.createObjectURL(blob);
  }
};

const errorUploading = (msg) => {
  snackbar.setBgColor('onSurface').setMsg(msg).setCustomClass('mb-5').displaySnackBar();
};

const addOrRemoveContentInTraining = (item) => {
  if (item?.content_type === 'Learn::Module') {
    const found = selectedModulesToAdd?.value?.find((content) => content?.id === item?.id);

    if (found) {
      learnTraining.value.modules_and_playlist = learnTraining?.value?.modules_and_playlist?.filter(
        (el) => !(el?.content_type === found?.content_type && el?.id === found?.id),
      );
      selectedModulesToAdd.value = selectedModulesToAdd?.value?.filter(
        (el) => el?.id !== found?.id,
      );
    } else {
      selectedModulesToAdd.value?.push(item);
      learnTraining.value.modules_and_playlist.push(item);
    }
  } else if (item?.content_type === 'Learn::Playlist') {
    const found = selectedPlaylistsToAdd?.value?.find((content) => content?.id === item?.id);

    if (found) {
      learnTraining.value.modules_and_playlist = learnTraining?.value?.modules_and_playlist?.filter(
        (el) => !(el?.content_type === found?.content_type && el?.id === found?.id),
      );
      selectedPlaylistsToAdd.value = selectedPlaylistsToAdd?.value?.filter(
        (el) => el?.id !== found?.id,
      );
    } else {
      selectedPlaylistsToAdd.value?.push(item);
      learnTraining.value.modules_and_playlist.push(item);
    }
  }

  modulesAndPlaylists.value = learnTraining.value.modules_and_playlist;
};

const editContent = (type, contentId) => {
  if (type === 'Learn::Module') {
    router.push({ name: 'module_edit', params: { id: contentId } });
  } else if (type === 'Learn::Playlist') {
    router.push({ name: 'playlist_edit', params: { id: contentId } });
  }
};

const restoreDeletedItem = async () => {
  try {
    const data = await restoreTraining(learnTraining?.value?.id);
    learnTraining.value.status = data?.learn_training?.status;
    snackbar
      .setBgColor('onSurface')
      .setMsg('Training has been restored successfully.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error restoring training.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  }
};

const deletePermanentlyDeletedItem = async () => {
  try {
    await deleteTrainingPermanently(learnTraining?.value?.id);
    snackbar
      .setBgColor('onSurface')
      .setMsg('Training has been deleted successfuly.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setMsg('Error deleting training.')
      .setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]')
      .displaySnackBar();
  }
};

const getListAfterDrag = async () => {
  modulesAndPlaylists.value = learnTraining?.value?.modules_and_playlist;
};

watch(
  learnTraining,
  (newValue) => {
    if (newValue?.title && titleError?.value) {
      titleError.value = false;
    }
    if (newValue?.launched_at_date && startDateError?.value) {
      startDateError.value = false;
    }
    if (newValue?.participant_filter_tag_ids?.length && participantsError?.value) {
      participantsError.value = false;
    }
    if (newValue?.participant_ids?.length && participantsError?.value) {
      participantsError.value = false;
    }
    if (newValue?.modules_and_playlist?.length && contentError?.value) {
      contentError.value = false;
    }
  },
  { deep: true },
);
</script>