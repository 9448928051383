<template>
  <div class="w-full flex flex-col gap-0 md:!gap-6 p-5 md:!p-0 md:!px-[72px] md:!pt-6 md:!pb-[116px]">
    <div class="flex flex-col md:!flex-row items-center w-full gap-2 justify-center md:!justify-between md:!gap-0">
      <svn-pro-text subtitle-large color="onSurfaceVariant" class="text-center">
        {{ templateType != 'Survey' ?
          $t("Participant’s firstname interview with Person in charge fullname") :
          $t('Survey - x participants') }}
      </svn-pro-text>

      <svn-pro-text
        subtitle-large
        color="onSurfaceVariant"
      >
        {{ $t("Deadline : dd/mm/yyyy") }}
      </svn-pro-text>
    </div>

    <div class="w-full h-full flex flex-col gap-6 !max-w-[752px] mx-auto">
      <div
        v-if="description"
        class="break-words w-full description rich-text-format"
        v-html="description"
      />

      <iframe
        v-if="isValidLink"
        id="ytplayer"
        class="mx-auto"
        type="text/html"
        width="100%"
        height="360"
        :src="
          'https://www.youtube.com/embed/' +
            video?.match(
              /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
            )[1]
        "
      />

      <question-component-template-preview
        v-for="(question, idx) in questionsData"
        :key="idx"
        :loop-idx="idx"
        :question="question?.data"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import QuestionComponentTemplatePreview from "@/components/interviewApp/interview_answers/QuestionComponentTemplatePreview.vue";

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps(["interviewQuestions", "title", "description", "video", "templateType"]);

const isValidLink = computed(() => {
  return props?.video && props?.video?.match(/youtube\.com/)
});

const questionsData = computed(() => {
  return props?.interviewQuestions?.map((question) => {
    return { data: question };
  });
});
</script>