export default class Reaction {
  constructor({ count, selected, type }) {
    this.count = count;
    this.selected = selected;
    this.type = type;
  }

  static fromJson(data) {
    return new Reaction({
      count: data?.['count'],
      selected: data?.['selected'],
      type: data?.['type'],
    });
  }
}
