<template>
  <div class="w-full h-full relative bg-surface">
    <div
      class="w-full h-full max-w-[1136px] mx-auto flex flex-col gap-4 items-center md:!items-start pt-5"
    >
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 px-5 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t('Campaigns') + $t(` (${campaignPagination?.total_count || 0})`) }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <svn-pro-button
              :text="$t('New campaign')"
              class="hidden md:flex"
              prepend-icon="custom:mingcute:add-line"
              variant="flat"
              @click="goToNewCampaign"
            />

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  color="onSurfaceVariant"
                  icon="custom:mingcute:more-2-fill"
                  v-bind="props"
                  variant="text"
                />
              </template>
              <template #dropdown>
                <v-list-item :active="false" value="view_deleted" @click="openDeletedItemModal">
                  <svn-pro-text body-large regular>
                    {{ $t('View deleted campaigns') }}
                  </svn-pro-text>
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Tabs -->
        <v-tabs v-model="tab" :grow="isMobile" class="px-5" color="primary">
          <!-- Current targets Tab -->
          <v-tab
            :text="`${$t('Current')} (${campaignPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="current"
          />

          <!-- Archived targets Tab -->
          <v-tab
            :text="`${$t('Upcoming')} (${campaignDraftPagination?.total_count || 0})`"
            class="w-1/2 md:w-auto"
            value="upcoming"
          />
        </v-tabs>

        <!-- Windows -->
        <v-window v-model="tab" class="px-5">
          <v-window-item value="current">
            <div
              :class="isMobile ? 'flex-col items-start' : 'flex-row items-center'"
              class="flex gap-4"
            >
              <!-- Search -->
              <svn-pro-text-field
                v-model="searchText"
                :placeholder="$t('Search')"
                class="w-full"
                prepend-inner-icon="custom:mingcute:search-2-line"
                variant="outlined"
                @update:model-value="reloadAfterSearch"
              />

              <!-- Toggle Filter button -->
              <svn-pro-button
                :append="activeFilterCount ? true : false"
                :prepend-icon="'custom:mingcute:filter-2-line'"
                :text="$t('Filter')"
                :variant="activeFilterCount ? 'tonal' : 'text'"
                @click="toggleFilterVisibility"
              >
                <template v-if="activeFilterCount" #append>
                  {{ `(${activeFilterCount})` }}
                </template>
              </svn-pro-button>
            </div>

            <!-- Filters -->
            <svn-pro-filter
              v-show="shouldShow"
              :filterItems="filterList"
              class="px-5 pt-5 xl:!px-0"
              @clear-all-filters="clearAllFilters"
              @update-selected-data-filters="fetchCampaignsAfterFilter"
            />

            <div class="w-full h-full flex flex-col gap-4">
              <campaign-table
                :campaign-pagination="campaignPagination"
                :search="searchText"
                @delete="deleteCampaign($event)"
                @go-to-new-campaign="goToNewCampaign"
              />
            </div>
          </v-window-item>

          <v-window-item value="upcoming">
            <div
              :class="isMobile ? 'flex-col items-start' : 'flex-row items-center'"
              class="flex gap-4"
            >
              <!-- Search -->
              <svn-pro-text-field
                v-model="draftSearchText"
                :placeholder="$t('Search')"
                class="w-full"
                prepend-inner-icon="custom:mingcute:search-2-line"
                variant="outlined"
                @update:model-value="reloadCampaignDraftsAfterSearch"
              />
            </div>

            <div class="w-full h-full flex flex-col gap-4">
              <campaign-draft-table
                :search="draftSearchText"
                @go-to-new-campaign="goToNewCampaign"
              />
            </div>
          </v-window-item>
        </v-window>

        <svn-pro-floating-action-button
          v-if="!isMdScreen"
          :text="$t('New Campaign')"
          class="fixed bottom-4 right-4"
          extended
          prepend-icon="custom:mingcute:add-line"
          variant="primary"
          @click="goToNewCampaign"
        />
      </div>
    </div>
  </div>

  <modal-view-deleted-items
    ref="deletedItemsRef"
    :headers="trashesHeaders"
    :items="trashedCampaigns"
    :pagination="trashCampaignPagination"
    content-type="Campaign"
    @restore="restoreDeletedItems"
    @close-modal="closeModal"
    @toggle-search="searchDeletedItems"
    @fetch-new-page="fetchTrashCampaign('', $event)"
    @delete-permanently="deletePermanently"
    @view-item="router.push({ name: 'campaign_show', params: { id: $event } })"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </modal-view-deleted-items>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import CampaignTable from './CampaignTable.vue';
import CampaignDraftTable from './CampaignDraftTable.vue';
import { onMounted, ref } from 'vue';
import { useCampaignDraftStore } from '@/store/campaign-draft';
import i18n from '@/plugins/i18n.js';
import { useUserStore } from '@/store/user';
import { debounce } from 'lodash';
import { useCampaignStore } from '@/store/campaign.js';
import { useCampaignTrashStore } from '@/store/campaign-trash.js';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar';
import { useRouter } from 'vue-router';
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import { useFilterStore } from '@/store/filters';
import { metadataApi } from "@/apis/metadata.api.js";

const { campaigns, params, pagination: campaignPagination } = storeToRefs(useCampaignStore());
const { trashedCampaigns, trashCampaignPagination } = storeToRefs(useCampaignTrashStore());
const {
  campaignDrafts,
  params: campaignDraftParams,
  pagination: campaignDraftPagination,
} = storeToRefs(useCampaignDraftStore());
const { fetchCampaigns, updateParams, setCampaigns, destroyCampaign } = useCampaignStore();
const { restoreCampaign, destroyPermanentlyCampaign, fetchTrashCampaign } = useCampaignTrashStore();
const {
  fetchCampaignDrafts,
  setCampaignDrafts,
  updateParams: updateParamsCampaignDraft,
} = useCampaignDraftStore();
const { columns } = storeToRefs(useFilterStore());

const { fetchFilterMetadata } = useFilterStore();
const router = useRouter();

const tab = ref(null);
const snackbar = useSnackbar();
const searchText = ref('');
const deletedItemsRef = ref(null);
const campaignIdToDelete = ref(null);
const filterInstance = ref(null); // To hold the filter model instance
const filterList = ref([]);

const activeFilterCount = ref(null);
const shouldShow = ref(false);
const draftSearchText = ref('');

const { isMobile, isMdScreen } = storeToRefs(useMobileStore());
const reloadAfterSearch = debounce(async (params) => {
  setCampaigns(null);
  updateParams({
    title: params,
    'page[number]': 1,
  });
  await fetchCampaigns();
}, 300);

const openDeletedItemModal = () => {
  deletedItemsRef.value.deletedItems.dialog = true;
};

const reloadCampaignDraftsAfterSearch = async (params) => {
  setCampaignDrafts(null);
  updateParamsCampaignDraft({
    title: params,
    'page[number]': 1,
  });
  fetchCampaignDrafts();
};

onMounted(async () => {
  fetchCampaigns().catch((e) => console.log(e));
  fetchCampaignDrafts().catch((e) => console.log(e));
  fetchTrashCampaign().catch((e) => console.log(e));
  await getFilterColumns();
});

const deleteCampaign = async (id) => {
  await destroyCampaign(id);
  const trash = campaigns?.value?.find((trash) => trash?.id === id);
  trash.deleted_at = new Date();
  trashedCampaigns?.value?.unshift(trash);
  campaigns.value = campaigns?.value?.filter((trash) => trash?.id !== id);
  snackbar.setBgColor('onSurface');
  snackbar.setMsg('Campaign has been deleted successfully.');
  snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]');
  snackbar.displaySnackBar();
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreCampaign(id);
    campaigns?.value?.unshift(trashedCampaigns?.value?.find((trash) => trash?.id === id));
    trashedCampaigns.value = trashedCampaigns?.value?.filter((trash) => trash?.id !== id);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Campaign has been restored successfully.');
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while restoring a training');
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  }
};

const deletePermanently = async () => {
  const id = campaignIdToDelete.value;

  try {
    await destroyPermanentlyCampaign(id);
    trashedCampaigns.value = trashedCampaigns?.value?.filter((trash) => trash?.id !== id);
    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Campaign has been deleted successfully.');
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  } catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error while deleting permanently a training');
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]');
    snackbar.displaySnackBar();
  }
};

const searchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashCampaign(value);
  } catch (e) {
    console.error(e);
  }
}, 300);

const goToNewCampaign = () => {
  router.push({ name: 'campaign_draft' });
};

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false;
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true;
  campaignIdToDelete.value = id;
};

const toggleFilterVisibility = () => {
  shouldShow.value = !shouldShow.value;
};

const clearAllFilters = async () => {
  params.value = {};
  activeFilterCount.value = null;

  await fetchCampaigns();
};

const fetchCampaignsAfterFilter = debounce(async (data) => {
  activeFilterCount.value = data?.length;
  if (data) {
    params.value = {
      filter_data: JSON.stringify(data),
    };
  } else {
    params.value = {};
  }

  await fetchCampaigns();
}, 300);

const trashesHeaders = ref([
  {
    align: 'start',
    key: 'title',
    sortable: true,
    title: i18n.global.t('Campaign title'),
    minWidth: 200,
  },
  {
    align: 'start',
    key: 'deleted_at',
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: 'start',
    key: 'restore',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
  {
    align: 'start',
    key: 'delete',
    sortable: false,
    title: '',
    width: 40,
    minWidth: 40,
  },
]);

const filterHeaders = ref([
  {
    name: 'campaign_type',
    title: i18n.global.t('Campaign type'),
  },
  {
    name: 'title',
    title: i18n.global.t('Campaign title'),
  },
  {
    name: 'startline',
    title: i18n.global.t('Period'),
  },
  {
    name: 'employees_count',
    title: 'Participants',
  },
  {
    name: 'completion',
    title: 'Completion',
  },
]);

// Main method to handle fetching and processing filter columns
const getFilterColumns = async () => {
  if (!filterInstance.value || !filterInstance.value.columns.length) {
    try {
      // Fetch metadata and initialize the Filter model
      filterInstance.value = await metadataApi.get("interview_app_campaigns");

      // Process each column
      filterInstance.value.columns.forEach(processColumn);
    } catch (error) {
      console.error("Error fetching filter metadata:", error);
    }
  }
};

// Process a single column
const processColumn = async (column) => {
  const foundCol = filterHeaders.value?.find(el => el?.name === column?.name);

  if (!foundCol) return; // Skip if no matching filter header is found
  handleGenericColumn(column, foundCol);
};

// Handle all other generic columns
const handleGenericColumn = (column, foundCol) => {
  const items = column?.answerOptions?.items

  const newCol = createFilterColumn(column, foundCol, items);
  filterList?.value?.push(newCol);
};

// Create a new filter column object
const createFilterColumn = (column, foundCol, items) => {
  return {
    value: '',
    operatorOptions: column?.operatorOptions,
    items,
    name: column?.name,
    type: column?.type,
    title: foundCol?.title,
  };
};
</script>
